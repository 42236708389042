import React from "react";
import reg_success from "../Assets/reg_success.json";
import Lottie from "react-lottie";
function RegSuccessful() {
  return (
    <>
      {" "}
      <div
        style={{
          marginTop: "5vh",
          fontSize: "2.9vh",
          fontWeight: "800",
          lineHeight: "3.7vh",
        }}
      >
        Well Done
      </div>
      <div
        style={{
          fontSize: "1.6vh",
          fontWeight: "500",
          lineHeight: "2.0vh",
          color: "#4F5969",
        }}
      >
        {" "}
        Thanks for registering!
      </div>
      <div
        className="border-gradient border-gradient-purple d-flex justify-content-center align-items-center flex-column"
        style={{
          // border: "2px solid blue",
          height: "66.7vh",
          // width: "29.25rem",

          marginTop: "3.8vh",
        }}
      >
        <Lottie
          options={{ animationData: reg_success }}
          style={{ width: "10vh", height: "10vh" }}
        />
        <div
          style={{
            fontSize: "2.6vh",
            lineHeight: "3.5vh",
            color: "#07152C",
            fontWeight: "800",
            marginTop: "1vh",
          }}
        >
          Registered Successfully
        </div>
        <div
          style={{
            fontSize: "1.3vh",
            fontWeight: "600",
            color: "#5B6574",
            lineHeight: "2.0vh",
            marginTop: "1vh",
          }}
        >
          Our Team will review & reach out to you soon, Thank You.
        </div>
      </div>
    </>
  );
}
export default RegSuccessful;
