import React from "react";
import side_info_bg from "../Assets/sid_info_bg.png";
import nyinst_logo from "../Assets/nyinst_logo.png";
import green_round from "../Assets/green_round.png";
import star_img from "../Assets/star_points.png";
function DesktopContent() {
  return (
    <div
      style={{
        backgroundImage: `url(${side_info_bg})`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <div className="" style={{ marginLeft: "20vh", paddingTop: "8vh" }}>
        <img
          src={nyinst_logo}
          alt="nyinst_logo"
          className=""
          style={{ width: "62px" }}
        />
        <div
          className=""
          style={{
            background:
              "linear-gradient(to bottom,  #bed9ff 39.58%, #065bd6 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: "6.2vh",
            lineHeight: "7vh",
            fontWeight: "800",
            marginTop: "1.8vh",
          }}
        >
          NYINST Registration
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div
            style={{
              fontSize: "3.5vh",
              fontWeight: "700",
              lineHeight: "40px",
              color: "#FFFFFF",
            }}
          >
            Programs
          </div>
          <div
            style={{
              height: "4px",
              width: "80px",
              background:
                "linear-gradient(63deg, #7B9970 0%, rgba(56, 73, 50, 0.00) 100%)",
              marginTop: "0.4vh",
            }}
          ></div>
          <div className="d-flex" style={{ marginTop: "1.6vh" }}>
            <div className="d-flex align-items-center">
              <img
                src={green_round}
                alt="green_round"
                style={{ width: "2.5vh", height: "2.5vh" }}
              />
              <span
                className="ps-2"
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#FFFFFF",
                }}
              >
                SUPER-100
              </span>
            </div>
            <div className="ps-4 d-flex align-items-center">
              <img
                src={green_round}
                alt="green_round"
                style={{ width: "2.5vh", height: "2.5vh" }}
              />
              <span
                className="ps-2"
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#FFFFFF",
                }}
              >
                TECHIE NERDS
              </span>
            </div>
          </div>
          <div
            style={{
              fontSize: "3.5vh",
              fontWeight: "500",
              lineHeight: "4vh",
              color: "#FFFFFF",
              marginTop: "5vh",
            }}
          >
            Our Offerings
          </div>
          <div
            style={{
              height: "4px",
              width: "80px",
              background:
                "linear-gradient(63deg, #F1B644 0%, rgba(241, 182, 68, 0.00) 100%)",
              marginTop: "0.4vh",
            }}
          ></div>
          <div className="row" style={{ marginTop: "2.1vh" }}>
            <div className="col-6">
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "1.6vh" }}
              >
                <img
                  src={star_img}
                  alt="star_img"
                  style={{ height: "2.5vh", width: "2.5vh" }}
                />
                <span
                  className="ps-2"
                  style={{
                    fontSize: "2vh",
                    lineHeight: "2.5vh",
                    color: "#FFFFFF",
                    fontWeight: "500",
                  }}
                >
                  100% Job Guarantee
                </span>
              </div>
            </div>
            <div className="col-6" align='left'>
              {" "}
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "1.6vh" }}
              >
                <img
                  src={star_img}
                  alt="star_img"
                  style={{ height: "2.5vh", width: "2.5vh" }}
                />
                <span
                  className="ps-2"
                  style={{
                    fontSize: "2vh",
                    lineHeight: "2.5vh",
                    color: "#FFFFFF",
                    fontWeight: "500",
                  }}
                >
                  100% Residential / In Chennai
                </span>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "3.7vh" }}>
            <div className="col-6">
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "1.6vh" }}
              >
                <img
                  src={star_img}
                  alt="star_img"
                  style={{ height: "2.5vh", width: "2.5vh" }}
                />
                <span
                  className="ps-2"
                  style={{
                    fontSize: "2vh",
                    lineHeight: "2.5vh",
                    color: "#FFFFFF",
                    fontWeight: "500",
                  }}
                >
                  100% Live Project
                </span>
              </div>
            </div>
            <div className="col-6">
              {" "}
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "1.6vh" }}
              >
                <img
                  src={star_img}
                  alt="star_img"
                  style={{ height: "2.5vh", width: "2.5vh" }}
                />
                <span
                  className="ps-2"
                  style={{
                    fontSize: "2vh",
                    lineHeight: "2.5vh",
                    color: "#FFFFFF",
                    fontWeight: "500",
                  }}
                >
                  No Pay Until Job Is Confirmed
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              fontSize: "3vh",
              fontWeight: "700",
              lineHeight: "2.5vh",
              color: "#FFFFFF",
              marginTop: "4vh",
            }}
          >
            Selection Process
          </div>
          <div
            style={{
              height: "4px",
              width: "80px",
              background:
                "linear-gradient(63deg, #5244F1 0%, #0D1117 100%, rgba(82, 68, 241, 0.00) 100%)",
              marginTop: "0.8vh",
            }}
          ></div>
          <div className="d-flex align-items-center" style={{ marginTop: "2.5vh" }}>
            <div className="d-flex align-items-center">
              <div
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#4B3FDD",
                }}
              >
                1.
              </div>
              <span
                className="ps-1"
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#FFFFFF",
                }}
              >
                HR Round
              </span>
            </div>
            <div className="ps-3 d-flex align-items-center" >
              <div
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#4B3FDD",
                }}
              >
                2.
              </div>
              <span
                className="ps-1"
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#FFFFFF",
                }}
              >
                Group Discussion
              </span>
            </div>
            <div className="ps-3 d-flex align-items-center">
              <div
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#4B3FDD",
                }}
              >
                3.
              </div>
              <span
                className="ps-1"
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#FFFFFF",
                }}
              >
               Aptitude & Tech Round
              </span>
            </div>
          </div>
        
          <div className="d-flex" style={{ marginTop: "1.6vh" }}>
            <div className="d-flex align-items-center">
              <div
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#4B3FDD",
                }}
              >
                4.
              </div>
              <span
                className="ps-1"
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#FFFFFF",
                }}
              >
                Prototype Project Assessment
              </span>
            </div>
            <div className="ps-3 d-flex align-items-center">
              <div
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#4B3FDD",
                }}
              >
                5.
              </div>
              <span
                className="ps-1"
                style={{
                  fontSize: "2vh",
                  fontWeight: "500",
                  lineHeight: "2.5vh",
                  color: "#FFFFFF",
                }}
              >
                Director Round
              </span>
            </div>
          </div>
          <div
            className="d-flex align-items-center"
            style={{
              backgroundImage:
                "linear-gradient(90deg, #3D35B0 0%, rgba(61, 53, 176, 0.00) 100%)",
              height: "4.5vh",
              borderRadius: "8px",
              marginTop: "2.6vh",
            }}
          >
            <div
              style={{
                fontSize: "2vh",
                color: "#FFFFFF",
                fontWeight: "500",
                lineHeight: "2.5vh",
                paddingLeft:"2.4vh"
              }}
            >
              <b>Note:</b> Qualified Candidate Will Be Notified By The Program{" "}
              Manager
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default DesktopContent;
