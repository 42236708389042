import React, { useRef, useState, useEffect, createRef } from "react";
import india_img from "../Assets/india.png";
import back_button from "../Assets/back_arrow_button.svg";
import "../App.css";
import { PinInput } from "react-input-pin-code";
import tick_vector from "../Assets/tick_vector.png";
import EducationDetails from "./EducationDetails";
import Content from "./Content";
import axios from "axios";
import qs from "qs";
import { ToastContainer, toast } from "react-toastify";
import { Bounce, Dots } from "react-activity";
import { MetroSpinner, PulseSpinner } from "react-spinners-kit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function PersonalDetails(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [state, setState] = useState([]);
  const [dist, setDist] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [dob, setDob] = useState();

  const [result, setResult] = useState();
  const [loading, setLoading] = useState(true);
  // const handleLoader = () => {
  //   alert("hi");
  //   setLoading(<Bounce color="#727981" size={32} speed={1} animating={true} />);
  // };

  const [pinCodeData, setPinCodeData] = useState({
    pincodeCity: "City",
    pincodeState: "State",
    pincodeDist: "District",
  });

  const [eduDetailShow, setEduDetailShow] = useState(true);
  const ref = useRef();

  const handleEduDetailShow = () => {
    setEduDetailShow(!eduDetailShow);
  };

  // console.log(propsData, "datprop");
  const getPostalCode = {
    mod: "Candidate",
    actionType: "get-postal-details",
    subAction: JSON.stringify({
      PINCODE: postal,
    }),
  };

  const genderList = [
    { value: "", text: "Gender" },
    { value: "Male", text: "Male" },
    { value: "Female", text: "Female" },
    { value: "Others", text: "Others" },
  ];
  const [gender, setGender] = useState(genderList[0].value);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log(postal);
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify(getPostalCode),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "data");
        setResult(res.data.XSCMessage);
        let responseData = res.data.XSCData;

        // if (result === "No Records Found") {
        //   toast.error("Enter Valid Pin");
        // }

        {
          responseData.CITY === null &&
          responseData.STATE === null &&
          responseData.CITY === null
            ? setPinCodeData({
                pincodeCity: "",
                pincodeDist: "",
                pincodeState: "",
              })
            : setPinCodeData({
                pincodeCity: responseData.CITY || city,
                pincodeDist: responseData.DISTRICT,
                pincodeState: responseData.STATE,
              });
          setCity(responseData.CITY || city);
          setDist(responseData.DISTRICT);
          setState(responseData.STATE);
        }
      });
      // Send Axios request here
      if (result === "Get postal details Listed Successfully") {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [postal]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log(dist);
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify({ mod: "Candidate", actionType: "get-state-list" }),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "data");
        let responseData = res.data.XSCData;
        setState(responseData);
      });
      // Send Axios request here
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  const propsData = props.CAND_ID;
  // console.log(propsData);
  const [item, setItem] = useState("");
  // useEffect(() => {
  //   localStorage.setItem("items", JSON.stringify(userDetails.item));
  //   console.log(item)
  // });

  const submitReqData = {
    mod: "Candidate",
    actionType: "add-cand-personal-details",
    subAction: JSON.stringify({
      CAND_ID: propsData.candID,
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      GENDER: gender,
      DOB: dob,
      STATE: pinCodeData.pincodeState || state,
      DISTRICT: pinCodeData.pincodeDist || dist,
      CITY: city || pinCodeData.pincodeCity,
      POSTAL_CODE: postal,
    }),
  };
  // console.log(submitReqData.subAction, "subAction");

  const handleProfile = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(submitReqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      // console.log(res, "submitdata");

      if (res.data.XSCStatus === 0) {
        toast.success("Personal Details Added Successfully!");
        handleEduDetailShow();
      } else toast.error("Failed To Add Details");
      // handleEduDetailShow()
    });
  };

  return (
    <div className="">
      <ToastContainer />

      {/* <div className="col-lg-6"></div> */}
      {eduDetailShow ? (
        <div className="form_content" style={{ marginLeft: "80px" }}>
          <div style={{ marginTop: "10vh" }}>
            <div
              style={{
                marginTop: "5vh",
                fontSize: "2.9vh",
                fontWeight: "800",
                lineHeight: "3.7vh",
              }}
            >
              Personal Details
            </div>
            <div
              style={{
                fontSize: "1.6vh",
                fontWeight: "500",
                lineHeight: "2.0vh",
                color: "#4F5969",
              }}
            >
              {" "}
              Fill in all basic details
            </div>
            <div
              className="border-gradient border-gradient-purple position-relative"
              style={{
                // border: "2px solid blue",
                height: "66.7vh",
                // width: "29.25rem",
                paddingTop: "4.1vh",
                paddingLeft: "3.3vh",
                marginTop: "3.8vh",
              }}
            >
              <form onSubmit={handleProfile}>
                <div className="position-relative">
                  <input
                    name="first_name"
                    id="first_name"
                    className=""
                    onChange={(e) =>
                      setFirstName(e.target.value.replace(/[^a-z]/gi, ""))
                    }
                    type="text"
                    // pattern="[a-zA-Z]"
                    value={firstName}
                    // placeholder="First Name"
                    style={{
                      height: "5.4vh",
                      marginTop: "2.5vh",
                      width: "90%",
                      fontSize: "1.7vh",
                      fontWeight: "400",
                      lineHeight: "2.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      color: "#07152C",
                      borderRadius: "4px",
                      paddingLeft: "1.6vh",
                    }}
                    required
                  />
                  <label
                    htmlFor="first_name"
                    className="position-absolute start-0 top-50 px-2 input-label"
                  >
                    First Name
                  </label>
                </div>
                <div className="position-relative">
                  <input
                    name="last_name"
                    id="last_name"
                    className="px-2"
                    onChange={(e) =>
                      setLastName(e.target.value.replace(/[^a-z]/gi, ""))
                    }
                    type="text"
                    value={lastName}
                    // placeholder="Last Name"
                    style={{
                      height: "5.4vh",
                      marginTop: "2.5vh",
                      width: "90%",
                      fontSize: "1.7vh",
                      fontWeight: "400",
                      lineHeight: "2.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      color: "#07152C",
                      borderRadius: "4px",
                    }}
                    required
                  />{" "}
                  <label
                    htmlFor="last_name"
                    className="position-absolute start-0 top-50 px-2 input-label"
                  >
                    Last Name
                  </label>{" "}
                </div>

                <div className="row mx-0" style={{ width: "90%" }}>
                  <div className="col-6 m-0 p-0">
                    <select
                      className="px-2"
                      required
                      placeholder="Gender"
                      value={gender}
                      id="Gender"
                      style={{
                        width: "100%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        borderRadius: "4px",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.5vh",
                        color: "#07152C",
                      }}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      {genderList.map((genders) => {
                        return (
                          <option
                            className="px-2"
                            placeholder="Gender"
                            value={genders.value}
                            style={{
                              width: "100%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",
                              fontSize: "2vh",
                              fontWeight: "400",
                              lineHeight: "3vh",
                              color: "#07152C",
                            }}
                            // onSelect={(e) => setGender(e.target.value)}
                          >
                            {genders.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-6 m-0 p-0">
                    {/* <div class="input-group date" id="datepicker">
        <input type="text" class="form-control" id="date"/>
        <span class="input-group-append">
          <span class="input-group-text bg-light d-block">
            <i class="fa fa-calendar"></i>
          </span>
        </span>
      </div> */}

                    <DatePicker
                      placeholderText="D.o.b"
                      value={dob}
                      dateFormat="MM/dd/yyyy"
                      onChange={(dob) => setDob(dob)}
                      selected={dob}
                      className="date-picker"
                      wrapperClassName="date-picker"
                      startDate={null}
                      maxDate={new Date()}
                    />

                    {/* <input
              style={{ border: "1.2px solid #DFE1E1" }}
              type="date"

              placeholder="BLOG DATE"
              value={dob}
              className="rounded w-100 my-2 p-2"
              onChange={(e)=> setDob(e.target.value)}
              required
            /> */}
                    <div className="position-relative">
                      {/* <input
                        ref={ref}
                        type="date"
                        onFocus={() => (ref.current.type = "date")}
                        onChange={(e) => setDob(e.target.value)}
                        className="px-2"
                        max='<?php echo date("Y-m-d");?>'
                        value={dob}
                        placeholder="D.O.B"
                        style={{
                          width: "90%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          marginLeft: "2vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          background: "white",
                          fontSize: "1.7vh",
                          fontWeight: "400",
                          color: "#07152C",
                          lineHeight: "2.4vh",
                        }}
                      /> */}
                      {/* <label className="position-absolute start-0 top-50input-label">
                        D.O.B
                      </label> */}
                    </div>
                  </div>
                </div>
                <div className="row mx-0" style={{ width: "90%" }}>
                  <div className="col-6 m-0 p-0 ">
                    <div className="position-relative">
                      <input
                        id="zip"
                        name="zip"
                        type="text"
                        inputmode="numeric"
                        pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
                        className="px-2"
                        maxLength={6}
                        onChange={(e) =>
                          setPostal(e.target.value.replace(/\D/g, ""))
                        }
                        value={postal}
                        // placeholder="Postal Code "
                        style={{
                          height: "5.4vh",
                          marginTop: "2.5vh",
                          width: "100%",
                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          color: "#07152C",
                          borderRadius: "4px",
                        }}
                        required
                      />{" "}
                      <label
                        htmlFor="zip"
                        className="position-absolute start-0 top-50 px-2 input-label"
                      >
                        Postal Code
                      </label>
                    </div>
                  </div>
                  <div className="col-6 m-0 p-0">
                    <div className="position-relative">
                      {" "}
                      <input
                        id="state"
                        name="state"
                        className="px-2"
                        maxLength={6}
                        onChange={(e) =>
                          setState(e.target.value.replace(/^([^0-9$%]*)$/))
                        }
                        value={pinCodeData.pincodeState}
                        // placeholder="Enter District"
                        style={{
                          height: "5.4vh",
                          marginTop: "2.5vh",
                          width: "90%",
                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          color: "#07152C",
                          borderRadius: "4px",
                          marginLeft: "2vh",
                        }}
                        required
                      />
                      <label
                        htmlFor="state"
                        className="position-absolute start-0 top-50 mx-4 input-label"
                      >
                        State
                      </label>
                    </div>

                    <MetroSpinner size={20} loading={loading} duration={500} />
                  </div>
                </div>
                <div className="row mx-0" style={{ width: "90%" }}>
                  <div className="col-6 m-0 p-0">
                    <div className="position-relative">
                      <input
                        className="px-2"
                        maxLength={6}
                        onChange={(e) =>
                          setDist(e.target.value.replace(/^([^0-9$%]*)$/))
                        }
                        value={pinCodeData.pincodeDist}
                        // placeholder="Enter District"
                        style={{
                          height: "5.4vh",
                          marginTop: "2.5vh",
                          width: "100%",
                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          color: "#07152C",
                          borderRadius: "4px",
                        }}
                        required
                      />
                      <label className="position-absolute start-0 top-50 px-2 input-label">
                        District
                      </label>
                    </div>
                  </div>
                  <div className="col-6 m-0 p-0">
                    <div className="position-relative">
                      <input
                        className="px-2"
                        // maxLength={6}
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                        // placeholder="Enter Dist"
                        style={{
                          height: "5.4vh",
                          marginTop: "2.5vh",
                          width: "90%",
                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          color: "#07152C",
                          borderRadius: "4px",
                          marginLeft: "2vh",
                        }}
                        required
                      />
                      <label className="position-absolute start-0 top-50 mx-4 input-label">
                        City
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="ps-3">
                              <MetroSpinner
                                size={20}
                                color="black"
                                loading={loading}
                              />
                            </div> */}

                <div
                  style={{
                    fontSize: "1.6vh",
                    fontWeight: "500",
                    lineHeight: "2.0vh",
                    color: "#6A7380",
                    marginTop: "2vh",
                  }}
                >
                  * Email & Phone number verified
                </div>
                <button
                  type="submit"
                  // onClick={handleProfile}
                  className="btn position-absolute bottom-0 mb-5"
                  style={{
                    width: "85%",
                    height: "5.4vh",
                    background: "var(--primary-color, #065BD6)",
                    fontSize: "",
                    fontWeight: "",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Next
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <EducationDetails CAND_ID={propsData} />
      )}
    </div>
  );
}

export default PersonalDetails;
