import React, { useState, useEffect } from "react";
import RegSuccessful from "./RegistrationSuccessful";
import axios from "axios";
import qs from "qs";
import { ToastContainer, toast } from "react-toastify";

function TopSkills(props) {
  const [show, setShow] = useState(true);

  const propsData = props.CAND_ID;

  const [skill1Name, setSkill1Name] = useState("");
  const [skill1Rating, setSkill1Rating] = useState("");
  const [skill1LastUsed, setSkill1LastUsed] = useState("");
  const [skill2Name, setSkill2Name] = useState("");
  const [skill2Rating, setSkill2Rating] = useState("");
  const [skill2LastUsed, setSkill2LastUsed] = useState("");
  const [skill3Name, setSkill3Name] = useState("");
  const [skill3Rating, setSkill3Rating] = useState("");
  const [skill3LastUsed, setSkill3LastUsed] = useState("");
  const [skill4Name, setSkill4Name] = useState("");
  const [skill4Rating, setSkill4Rating] = useState("");
  const [skill4LastUsed, setSkill4LastUsed] = useState("");
  const [skill5Name, setSkill5Name] = useState("");
  const [skill5Rating, setSkill5Rating] = useState("");
  const [skill5LastUsed, setSkill5LastUsed] = useState("");

  const handleShow = () => {
    setShow(!show);
  };

  const skillsReqData = {
    mod: "Candidate",
    actionType: "add-skill-details",
    subAction: JSON.stringify({
      SKILLS_DETAILS: [
        {
          SKILL_NAME: skill1Name,
          RATING: skill1Rating,
          LAST_USED: skill1LastUsed,
        },
        {
          SKILL_NAME: skill2Name,
          RATING: skill2Rating,
          LAST_USED: skill2LastUsed,
        },
        {
          SKILL_NAME: skill3Name,
          RATING: skill3Rating,
          LAST_USED: skill3LastUsed,
        },
        {
          SKILL_NAME: skill4Name,
          RATING: skill4Rating,
          LAST_USED: skill4LastUsed,
        },
        {
          SKILL_NAME: skill5Name,
          RATING: skill5Rating,
          LAST_USED: skill5LastUsed,
        },
      ],
      CAND_ID: propsData.candID,
    }),
  };
  const [rating, setRating] = useState([]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify({ mod: "Candidate", actionType: "get-rating-list" }),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "data");
        let responseData = res.data.XSCData;
        setRating(responseData);
      });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);
  const [lastUsed, setLastUsed] = useState([]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify({
          mod: "Candidate",
          actionType: "get-last-used-list",
        }),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "data");
        let responseData = res.data.XSCData;
        setLastUsed(responseData);
      });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);
  const handleSkills = (e) => {
    e.preventDefault()
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(skillsReqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      // console.log(res, "submitdata");
      // console.log(skillsReqData.subAction, "subaction");
      res.data.XSCStatus === 0
        ? handleShow()
        : toast.error("Failed To Add Details");
    });
  };
  // console.log(skillsReqData.subAction, "sub");
  return (
    <div>
      <ToastContainer />
      {show ? (
        <>
          {" "}
          <div
            className="form_content"
            style={{
              marginTop: "5vh",
              fontSize: "2.9vh",
              fontWeight: "800",
              lineHeight: "3.7vh",
            }}
          >
            Your Top Skills
          </div>
          <div
            style={{
              fontSize: "1.6vh",
              fontWeight: "500",
              lineHeight: "2.0vh",
              color: "#4F5969",
            }}
          >
            {" "}
            Make sure its accurate
          </div>
          <div
            align="center"
            className="border-gradient border-gradient-purple position-relative"
            style={{
              // border: "2px solid blue",
              height: "66.7vh",
              // width: "29.25rem",
              paddingTop: "4.1vh",

              marginTop: "3.8vh",
            }}
          >
            <div
              style={{
                overflowY: "scroll",
                scrollBehavior: "smooth",
                height: "50vh",
                scrollMarginRight: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "1.6vh",
                  color: "#222F43",
                  lineHeight: "2.4vh",
                  fontWeight: "700",
                }}
              >
                Mention top 5 technical skills that are you comfortable <br />
                with{" "}
                <span
                  style={{
                    fontSize: "1.3vh",
                    color: "#4F5969",
                    lineHeight: "2.4vh",
                    fontWeight: "400",
                  }}
                >
                  (* You will be required to take the test in these skills)
                </span>
              </div>
              <form onSubmit={handleSkills}>
                <div
                  className="mt-2 d-flex align-items-center justify-content-center "
                  style={{
                    fontSize: "1.2vh",
                    color: "#5B6574",
                    fontWeight: "600",
                    lineHeight: "1.6vh",
                  }}
                >
                  <div
                    className=""
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                  <div className="ps-2 pe-2">Skill 1 </div>
                  <div
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                </div>
                <div className="position-relative">
                  <input
                    name="top-skills1"
                    id="top-skills1"
                    className="px-4"
                    onChange={(e) => setSkill1Name(e.target.value)}
                    type="text"
                    value={skill1Name}
                    // onChange={(e) => setSkill2({ RATING: e.target.value })}
                    // placeholder="Tech Skills e.g. C, C++, Java, Python.."
                    style={{
                      height: "5.4vh",
                      marginTop: "2vh",
                      width: "90%",
                      fontSize: "1.7vh",
                      fontWeight: "400",
                      lineHeight: "2.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      color: "#07152C",
                    }}
                    required
                  />
                  <label
                    htmlFor="top-skills1"
                    className="position-absolute start-0 top-50 px-4 input-label"
                  >
                    Tech Skills
                  </label>
                </div>

                <div className="row mx-0" style={{ width: "90%" }}>
                  <div className="col-6 m-0 p-0">
                    <select
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "100%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                      }}
                      onChange={(e) => setSkill1Rating(e.target.value)}
                    >
                      <option
                        className="px-4"
                        placeholder="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          fontSize: "1.5vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                      >
                        Select Ratings
                      </option>
                      {rating.map((ratingList) => {
                        return (
                          <option
                            key={ratingList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={ratingList.LOOKUP_ID}
                            style={{
                              width: "90%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",

                              fontSize: "1.5vh",
                              fontWeight: "400",
                              lineHeight: "2.4vh",
                            }}
                          >
                            {ratingList.RATINGS}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-6 m-0 p-0">
                    <select 
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "90%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.5vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        marginLeft: "2vh",
                      }}
                      onChange={(e) => setSkill1LastUsed(e.target.value)}
                    >
                      <option 
                        className="px-4"
                        placeholder="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",

                          fontSize: "1.5vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                      >
                        Select Last Used
                      </option>
                      {lastUsed.map((lastusedList) => {
                        return (
                          <option
                            key={lastusedList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={lastusedList.LOOKUP_ID}
                            style={{
                              width: "100%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",

                              fontSize: "1.5vh",
                              fontWeight: "400",
                              lineHeight: "2.4vh",
                            }}
                          >
                            {lastusedList.LAST_USED}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div
                  className="mt-3 d-flex align-items-center justify-content-center "
                  style={{
                    fontSize: "1.2vh",
                    color: "#5B6574",
                    fontWeight: "600",
                    lineHeight: "1.6vh",
                  }}
                >
                  <div
                    className=""
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                  <div className="ps-2 pe-2">Skill 2 </div>
                  <div
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                </div>
                <div className="position-relative">
                  <input
                    name="top-skills2"
                    id="top-skills2"
                    className="px-2"
                    onChange={(e) => setSkill2Name(e.target.value)}
                    type="text"
                    value={skill2Name}
                    // onChange={(e) => setSkill2({ RATING: e.target.value })}
                    // placeholder="Tech Skills e.g. C, C++, Java, Python.."
                    style={{
                      height: "5.4vh",
                      marginTop: "2vh",
                      width: "90%",
                      fontSize: "1.7vh",
                      fontWeight: "400",
                      lineHeight: "2.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      color: "#07152C",
                    }}
                    required
                  />
                  <label
                    htmlFor="top-skills2"
                    className="position-absolute start-0 top-50 px-4 input-label"
                  >
                    Tech Skills
                  </label>
                </div>

                <div className="row mx-0" style={{ width: "90%" }}>
                  <div className="col-6 m-0 p-0">
                    <select
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "100%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                      }}
                      onChange={(e) => setSkill2Rating(e.target.value)}
                    >
                      <option
                        className="px-4"
                        placeholder="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",

                          fontSize: "1.5vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                      >
                        Select Ratings
                      </option>
                      {rating.map((ratingList) => {
                        return (
                          <option
                            key={ratingList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={ratingList.LOOKUP_ID}
                            style={{
                              width: "90%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",
                            }}
                          >
                            {ratingList.RATINGS}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-6 m-0 p-0">
                    <select
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "90%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        marginLeft: "2vh",
                      }}
                      onChange={(e) => setSkill2LastUsed(e.target.value)}
                    >
                      <option
                        className="px-2"
                        placeholder="State"
                        value={0}
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                        }}
                      >
                        Select Last Used
                      </option>
                      {lastUsed.map((lastusedList) => {
                        return (
                          <option
                            key={lastusedList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={lastusedList.LOOKUP_ID}
                            style={{
                              width: "100%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",
                            }}
                          >
                            {lastusedList.LAST_USED}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div
                  className="mt-3 d-flex align-items-center justify-content-center "
                  style={{
                    fontSize: "1.2vh",
                    color: "#5B6574",
                    fontWeight: "600",
                    lineHeight: "1.6vh",
                  }}
                >
                  <div
                    className=""
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                  <div className="ps-2 pe-2">Skill 3 </div>
                  <div
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                </div>
                <div className="position-relative">
                  <input
                    name="top-skills3"
                    id="top-skills3"
                    className="px-2"
                    onChange={(e) => setSkill3Name(e.target.value)}
                    type="text"
                    value={skill3Name}
                    // onChange={(e) => setSkill2({ RATING: e.target.value })}
                    // placeholder="Tech Skills e.g. C, C++, Java, Python.."
                    style={{
                      height: "5.4vh",
                      marginTop: "2vh",
                      width: "90%",
                      fontSize: "1.7vh",
                      fontWeight: "400",
                      lineHeight: "2.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      color: "#07152C",
                    }}
                    required
                  />
                  <label
                    htmlFor="top-skills3"
                    className="position-absolute start-0 top-50 px-4 input-label"
                  >
                    Tech Skills
                  </label>
                </div>

                <div className="row mx-0" style={{ width: "90%" }}>
                  <div className="col-6 m-0 p-0">
                    <select
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "100%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                      }}
                      onChange={(e) => setSkill3Rating(e.target.value)}
                    >
                      <option
                        className="px-4"
                        placeholder="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                        }}
                      >
                        Select Ratings
                      </option>
                      {rating.map((ratingList) => {
                        return (
                          <option
                            key={ratingList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={ratingList.LOOKUP_ID}
                            style={{
                              width: "90%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",
                            }}
                          >
                            {ratingList.RATINGS}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-6 m-0 p-0">
                    <select
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "90%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        marginLeft: "2vh",
                      }}
                      onChange={(e) => setSkill3LastUsed(e.target.value)}
                    >
                      <option
                        className="px-4"
                        placeholder="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                        }}
                      >
                        Select Last Used
                      </option>
                      {lastUsed.map((lastusedList) => {
                        return (
                          <option
                            key={lastusedList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={lastusedList.LOOKUP_ID}
                            style={{
                              width: "100%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",
                            }}
                          >
                            {lastusedList.LAST_USED}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div
                  className="mt-3 d-flex align-items-center justify-content-center "
                  style={{
                    fontSize: "1.2vh",
                    color: "#5B6574",
                    fontWeight: "600",
                    lineHeight: "1.6vh",
                  }}
                >
                  <div
                    className=""
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                  <div className="ps-2 pe-2">Skill 4 </div>
                  <div
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                </div>
                <div className="position-relative">
                  <input
                    name="top-skills4"
                    id="top-skills4"
                    className="px-2"
                    onChange={(e) => setSkill4Name(e.target.value)}
                    type="text"
                    value={skill4Name}
                    // onChange={(e) => setSkill2({ RATING: e.target.value })}
                    // placeholder="Tech Skills e.g. C, C++, Java, Python.."
                    style={{
                      height: "5.4vh",
                      marginTop: "2vh",
                      width: "90%",
                      fontSize: "1.7vh",
                      fontWeight: "400",
                      lineHeight: "2.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      color: "#07152C",
                    }}
                    required
                  />
                  <label
                    htmlFor="top-skills4"
                    className="position-absolute start-0 top-50 px-4 input-label"
                  >
                    Tech Skills
                  </label>
                </div>

                <div className="row mx-0" style={{ width: "90%" }}>
                  <div className="col-6 m-0 p-0">
                    <select
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "100%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                      }}
                      onChange={(e) => setSkill4Rating(e.target.value)}
                    >
                      <option
                        className="px-4"
                        placeholder="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                        }}
                      >
                        Select Ratings
                      </option>
                      {rating.map((ratingList) => {
                        return (
                          <option
                            key={ratingList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={ratingList.LOOKUP_ID}
                            style={{
                              width: "90%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",
                            }}
                          >
                            {ratingList.RATINGS}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-6 m-0 p-0">
                    <select
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "90%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        marginLeft: "2vh",
                      }}
                      onChange={(e) => setSkill4LastUsed(e.target.value)}
                    >
                      <option
                        className="px-4"
                        placeholder="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                        }}
                      >
                        Select Last Used
                      </option>
                      {lastUsed.map((lastusedList) => {
                        return (
                          <option
                            key={lastusedList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={lastusedList.LOOKUP_ID}
                            style={{
                              width: "100%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",
                            }}
                          >
                            {lastusedList.LAST_USED}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div
                  className="mt-3 d-flex align-items-center justify-content-center "
                  style={{
                    fontSize: "1.2vh",
                    color: "#5B6574",
                    fontWeight: "600",
                    lineHeight: "1.6vh",
                  }}
                >
                  <div
                    className=""
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                  <div className="ps-2 pe-2">Skill 5 </div>
                  <div
                    style={{
                      height: "1px",
                      background: "#EBECEE",
                      width: "100px",
                    }}
                  ></div>{" "}
                </div>
                <div className="position-relative">
                  <input
                    name="top-skills5"
                    id="top-skills5"
                    className="px-2 z-1"
                    onChange={(e) => setSkill5Name(e.target.value)}
                    type="text"
                    value={skill5Name}
                    // onChange={(e) => setSkill2({ RATING: e.target.value })}
                    // placeholder="Tech Skills e.g. C, C++, Java, Python.."
                    style={{
                      height: "5.4vh",
                      marginTop: "2vh",
                      width: "90%",
                      fontSize: "1.7vh",
                      fontWeight: "400",
                      lineHeight: "2.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      color: "#07152C",
                    }}
                    required
                  />
                  <label
                    htmlFor="top-skills5"
                    className="position-absolute start-0 top-50 px-4 input-label"
                    style={{ textAnchor: "start" }}
                  >
                    Tech Skills
                  </label>
                </div>

                <div className="row mx-0" style={{ width: "90%" }}>
                  <div className="col-6 m-0 p-0">
                    <select
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "100%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                      }}
                      onChange={(e) => setSkill5Rating(e.target.value)}
                    >
                      <option
                        className="px-4"
                        placeholder="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                        }}
                      >
                        Select Ratings
                      </option>
                      {rating.map((ratingList) => {
                        return (
                          <option
                            key={ratingList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={ratingList.LOOKUP_ID}
                            style={{
                              width: "90%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",
                            }}
                          >
                            {ratingList.RATINGS}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-6 m-0 p-0">
                    <select
                      required
                      placeholder="State"
                      name="State"
                      id="State"
                      style={{
                        width: "90%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        background: "white",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        marginLeft: "2vh",
                      }}
                      onChange={(e) => setSkill5LastUsed(e.target.value)}
                    >
                      <option
                        className="px-4"
                        placeholder="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                        }}
                      >
                        Select Last Used
                      </option>
                      {lastUsed.map((lastusedList) => {
                        return (
                          <option
                            key={lastusedList.LOOKUP_ID}
                            className="px-2"
                            placeholder="State"
                            value={lastusedList.LOOKUP_ID}
                            style={{
                              width: "100%",
                              marginTop: "2.5vh",
                              height: "5.4vh",
                              border: "1px solid var(--grey-g-40, #DFE1E4)",
                            }}
                          >
                            {lastusedList.LAST_USED}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="position-absolute bottom-0 w-100 mb-3 mx-4">
                  <div className="row">
                    {/* <div
                    className="col-4 mx-2"
                    style={{
                      height: "5.4vh",
                      background: "var(--primary-color, #EBECEE)",
                      fontSize: "1.3vh",
                      fontWeight: "600",
                      color: "#5B6574",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                    }}
                  >
                    Back
                  </div> */}
                    <button type="submit"
                      // onClick={handleSkills}
                      className="col-10 mx-2 btn"
                      style={{
                        height: "5.4vh",
                        background: "var(--primary-color, #065BD6)",
                        fontSize: "1.3vh",
                        fontWeight: "600",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Submit Registration Form
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <RegSuccessful />
      )}
    </div>
  );
}
export default TopSkills;
