import React, { useState, useEffect, createRef } from "react";
import upload_icon from "../Assets/upload_icon.png";
import TopSkills from "./TopSkils";
import axios from "axios";
import qs from "qs";
import { ToastContainer, toast } from "react-toastify";
import file_img from "../Assets/file_img.png";
import edit_resume_icon from "../Assets/resume_edit_icon.png";

function CourseExp(props) {
  const [certName, setCertName] = useState("");
  const [instName, setInstName] = useState("");
  const [courseState, setCourseState] = useState("");
  const [city, setCity] = useState("");
  const [duration, setDuration] = useState("");
  const [skills, setSkills] = useState("");
  const [userData, setUserData] = useState("");
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState(" ");

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [show, setShow] = useState(true);

  const handleShow = () => {
    setShow(!show);
  };

  const [previousShow, setPreviousShow] = useState(true);
  const handlePrevShow = () => {
    setPreviousShow(!previousShow);
  };
  const [durationList, setDurationList] = useState([]);
  const [state, setState] = useState([]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify({ mod: "Candidate", actionType: "get-state-list" }),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "data");
        let responseData = res.data.XSCData;
        setState(responseData);
        // let states = state.sort(function (a, b) {
        //   return a.STATE === b.STATE ? 0 : a.STATE < b.STATE ? -1 : 1;
        // });
        // setState(states)
      });
      // Send Axios request here
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    let states = state.sort(function (a, b) {
      return a.STATE === b.STATE ? 0 : a.STATE < b.STATE ? -1 : 1;
    });
    setState(states);
    // console.log(states, "jk");
  }, []);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify({
          mod: "Candidate",
          actionType: "get-course-period",
        }),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "data");
        let responseData = res.data.XSCData;
        setDurationList(responseData);
      });
      // Send Axios request here
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);
  const handleCollegeStateId = (e) => {
    // console.log(e.target.value);
    setCourseState(e.target.value);
    // console.log(collegeState);
    getCityList(e.target.value);

    // getCityList()
  };
  const [cityLists, setCityLists] = useState([]);
  const getCityList = (e) => {
    // console.log(e, "egg");

    const delayDebounceFn = setTimeout(() => {
      const getCity = {
        mod: "Candidate",
        actionType: "get-city-list",
        subAction: JSON.stringify({ STATE_ID: e }),
      };

      // console.log(getCity.subAction, "citysubaction");
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify(getCity),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "datass");
        let responseData = res.data.XSCData;
        setCityLists(responseData);
      });
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  };
  const handleImage = (e) => {
    const file = e.target.files[0];
    setResume(file);
    setResumeName(e.target.files[0].name);
    // console.log(resume);
  };
  const propsData = props.CAND_ID;
  // console.log(propsData,"io")
  const handleCourse = (e) => {
    // e.preventDefault();
    // const formData = new FormData();

    const formData = new FormData();
    formData.append("DOC_FILE", resume);
    formData.append("mod", "Candidate");
    formData.append("actionType", "add-course-experience");
    formData.append(
      "subAction",
      JSON.stringify({
        CERTIFICATE_NAME: certName,
        COURSE_INST_NAME: instName,
        STATE_ID: courseState,
        CITY_ID: city,
        DURATION_COURSE: duration,
        CAND_ID: propsData.candID,
        SKILLS: skills,
        DESCRIPTION: userData,
      })
    );

    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      // console.log(response.data);

      if (response.data.XSCStatus === 0) {
        toast.success("Course Added Successfully!");
        handleShow();
      } else toast.error("Failed to add");
      // handleShow();
    });
  };
  // const inputRef = createRef();
  const [courseExp, setCourseExp] = useState("yes");
  const [hide, setHide] = useState(true);

  return (
    <div>
      <ToastContainer />
      {show ? (
        <>
          {" "}
          <div
            className="form_content"
            style={{
              marginTop: "5vh",
              fontSize: "2.9vh",
              fontWeight: "800",
              lineHeight: "3.7vh",
            }}
          >
            Course/Experience
          </div>
          <div
            style={{
              fontSize: "1.6vh",
              fontWeight: "500",
              lineHeight: "2.0vh",
              color: "#4F5969",
            }}
          >
            {" "}
            Based on your
          </div>
          <div
            className="border-gradient border-gradient-purple position-relative"
            style={{
              // border: "2px solid blue",
              height: "66.7vh",
              // width: "29.25rem",
              paddingTop: "4.1vh",
              paddingLeft: "3.3vh",
              marginTop: "3.8vh",
            }}
          >
            <div
              style={{
                overflowY: "scroll",
                scrollBehavior: "smooth",
                height: "50vh",
                scrollMarginRight: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "1.6vh",
                  color: "#222F43",
                  lineHeight: "2.4vh",
                  fontWeight: "500",
                }}
              >
                Do you have any relevant IT Industry Experience?
              </div>
              <div className="d-flex mt-2 align-items-center">
                <form className="d-flex">
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      id="yess"
                      name="yess"
                      value="yess"
                      checked
                    />
                    <label
                      for="yess"
                      className="mt-1 px-2"
                      style={{
                        fontSize: "1.3vh",
                        color: "#5B6574",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      Yes
                    </label>
                     {" "}
                    <input
                      type="radio"
                      id="noo"
                      name="noo"
                      // value="noo"
                    />
                     {" "}
                    <label
                      // for="noo"
                      className="mt-1 ps-2"
                      style={{
                        fontSize: "1.3vh",
                        color: "#5B6574",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      No
                    </label>
                    {/* <input type="radio" id="" name="fav_language" value="Yes" checked />
                     {" "}
                    <label
                      for="yes"
                      className="mt-1"
                      style={{
                        fontSize: "1.3vh",
                        color: "#5B6574",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      Yes
                    </label> */}
                  </div>
                  <div className="d-flex mx-3 align-items-center">
                    {/* <input type="radio" id="no" name="fav_language" value="No"  />
                    <label
                      for="no"
                      className="mt-1 ps-2"
                      style={{
                        fontSize: "1.3vh",
                        color: "#5B6574",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      No
                    </label> */}
                  </div>
                </form>
              </div>
              <div
                style={{
                  fontSize: "1.6vh",
                  color: "#222F43",
                  lineHeight: "2.4vh",
                  fontWeight: "500",
                  marginTop: "2vh",
                }}
              >
                Have you taken any IT related Course?
              </div>
              <div className="d-flex mt-2">
                <form className="d-flex">
                  <div className="d-flex align-items-center">
                    <input
                      onClick={(e) =>
                        setCourseExp(e.target.value) + setHide(true)
                      }
                      type="radio"
                      id="yes"
                      name=""
                      value="yes"
                      checked={courseExp === "yes"}
                    />
                     {" "}
                    <label
                      // for="html"
                      className="mt-1"
                      style={{
                        fontSize: "1.3vh",
                        color: "#5B6574",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      Yes
                    </label>
                  </div>
                  <div className="d-flex mx-3 align-items-center">
                    <input
                      type="radio"
                      id="No"
                      name=""
                      value="no"
                      checked={courseExp === "no"}
                      onClick={(e) =>
                        setCourseExp(e.target.value) + setHide(false)
                      }
                    />{" "}
                    <label
                      for="html"
                      className="mt-1 ps-2"
                      style={{
                        fontSize: "1.3vh",
                        color: "#5B6574",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      No
                    </label>
                  </div>
                </form>
              </div>
              {hide ? (
                <form>
                  <div className="position-relative">
                    {" "}
                    <input
                      className="px-2"
                      onChange={(e) => setCertName(e.target.value)}
                      type="text"
                      name="certificate-name"
                      id="certificate-name"
                      value={certName}
                      // placeholder="Name of the Certificate"
                      // pattern="^[a-zA-Z]"
                      style={{
                        height: "5.4vh",
                        marginTop: "2vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                      }}
                      required
                    />
                    <label
                      htmlFor="certificate-name"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      Name Of the Certificate
                    </label>
                  </div>
                  <div className="position-relative">
                    <input
                      name="inst_name"
                      id="inst_name"
                      className="px-2"
                      onChange={(e) => setInstName(e.target.value)}
                      type="text"
                      value={instName}
                      // placeholder="Name of Training Centre or Platform"
                      style={{
                        height: "5.4vh",
                        marginTop: "2vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                      }}
                      required
                    />
                    <label
                      htmlFor="inst_name"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      Name of Training Centre or Platform
                    </label>
                  </div>

                  <div className="row mx-0" style={{ width: "90%" }}>
                    <div className="col-6 m-0 p-0">
                      <select
                        placeholder="State"
                        name="State"
                        id="State"
                        style={{
                          width: "90%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          background: "white",

                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                        onChange={handleCollegeStateId}
                      >
                        <option
                          className="px-2"
                          placeholder="State"
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                          }}
                        >
                          Select State
                        </option>
                        {state.map((stateList) => {
                          return (
                            <option
                              key={stateList.ID}
                              className="px-2"
                              placeholder="State"
                              value={stateList.ID}
                              style={{
                                width: "100%",
                                marginTop: "2.5vh",
                                height: "5.4vh",
                                border: "1px solid var(--grey-g-40, #DFE1E4)",

                                fontSize: "1.7vh",
                                fontWeight: "400",
                                lineHeight: "2.4vh",
                              }}
                            >
                              {stateList.STATE}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-6 m-0 p-0">
                      <select
                        placeholder="State"
                        name="State"
                        id="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          background: "white",

                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                        onChange={(e) => setCity(e.target.value)}
                      >
                        <option
                          // key={stateList.ID}
                          className="px-2"
                          placeholder="State"
                          // value={.ID}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                          }}
                        >
                          Select City
                        </option>
                        {cityLists &&
                          cityLists.map((cityList) => {
                            return (
                              <option
                                key={cityList.ID}
                                className="px-2"
                                placeholder="State"
                                value={cityList.ID}
                                onChange={getCityList}
                                style={{
                                  width: "100%",
                                  marginTop: "2.5vh",
                                  height: "5.4vh",
                                  border: "1px solid var(--grey-g-40, #DFE1E4)",
                                }}
                              >
                                {cityList.CITY}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <select
                    className="px-2"
                    placeholder="State"
                    name="State"
                    id="State"
                    style={{
                      width: "90%",
                      marginTop: "2.5vh",
                      height: "5.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      background: "white",

                      fontSize: "1.7vh",
                      fontWeight: "400",
                      lineHeight: "2.4vh",
                    }}
                    onChange={(e) => setDuration(e.target.value)}
                  >
                    <option
                      className="px-4"
                      placeholder="State"
                      style={{
                        width: "100%",
                        marginTop: "2.5vh",
                        height: "5.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                      }}
                    >
                      Select Course Period
                    </option>
                    {durationList.map((duration) => {
                      return (
                        <option
                          key={duration.LOOKUP_ID}
                          className="px-4"
                          placeholder="State"
                          value={duration.LOOKUP_ID}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                          }}
                        >
                          {duration.COURSE_PERIOD}
                        </option>
                      );
                    })}
                  </select>

                  <textarea
                    className="px-2"
                    onChange={(e) => setSkills(e.target.value)}
                    type="text"
                    value={skills}
                    placeholder="Skills Learned"
                    style={{
                      height: "8.7vh",
                      marginTop: "2.5vh",
                      width: "90%",
                      fontSize: "1.7vh",
                      fontWeight: "400",
                      lineHeight: "2.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      color: "#07152C",
                    }}
                    required
                  />
                </form>
              ) : (
                ""
              )}
              <input
                accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                id="file-input"
                type="file"
                style={{ display: "none" }}
                onChange={handleImage}
              />
              <label
                // onChange={(e) => setResume(e.target.value)}
                for="file-input"
                class="custom-file-label"
                style={{
                  background: "#065BD60D",
                  width: "90%",
                  border: "1px dashed #065BD6",
                  height: "7.0vh",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.3vh",
                  fontWeight: "700",
                  lineHeight: "2.0vh",
                  color: "#07152C",
                  marginTop: "2vh",
                }}
              >
                <img
                  src={upload_icon}
                  alt="upload"
                  style={{ height: "3vh", width: "3vh" }}
                />
                <span className="ps-2">
                  Upload Latest Resume
                  <br />
                  <span
                    style={{
                      fontSize: "1vh",
                      color: "#5B6574",
                      fontWeight: "400",
                      lineHeight: "1.6vh",
                    }}
                  >
                    {resumeName}
                  </span>
                </span>
              </label>
              {/* <label
                // onChange={(e) => setResume(e.target.value)}
                for="file-input"
                className="custom-file-label d-flex align-items-center justify-content-between"
                style={{
                  background: "none",
                  width: "90%",
                  border: "1px solid #DFE1E4",
                  height: "7.0vh",
                  borderRadius: "4px",

                  fontSize: "1.3vh",
                  fontWeight: "700",
                  lineHeight: "2.0vh",
                  color: "#07152C",
                  marginTop: "2vh",
                }}
              >
                <div className="d-flex align-items-center">
                  <img className="mx-2"
                    src={file_img}
                    alt="upload"
                    style={{ height: "3.5vh", width: "3.5vh" }}
                  />
                  <span className="ps-3">
                  
                    <span
                      style={{
                        fontSize: "1.5vh",
                        color: "#5B6574",
                        fontWeight: "600",
                        lineHeight: "1.6vh",
                      }}
                    >
                      {resumeName}
                    </span>
                  </span>
                </div>
                <img className="me-3"
                  src={edit_resume_icon}
                  alt="upload"
                  style={{ height: "3vh", width: "3vh" }}
                />
              </label> */}
              <textarea
                className="px-2"
                onChange={(e) => setUserData(e.target.value)}
                type="text"
                value={userData}
                maxLength={250}
                placeholder="Briefly Tell About yourself (Max250 words)"
                style={{
                  height: "10.7vh",
                  marginTop: "2.5vh",
                  width: "90%",
                  fontSize: "1.7vh",
                  fontWeight: "400",
                  lineHeight: "2.4vh",
                  border: "1px solid var(--grey-g-40, #DFE1E4)",
                  color: "#07152C",
                  borderRadius: "4px",
                }}
                required
              />
            </div>

            <div className="position-absolute bottom-0 w-100 mb-3">
              <div className="row">
                {/* <div
                  onClick={handleShow}
                  className="col-4 mx-2"
                  style={{
                    height: "5.4vh",
                    background: "var(--primary-color, #EBECEE)",
                    fontSize: "1.3vh",
                    fontWeight: "600",
                    color: "#5B6574",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                  }}
                >
                  Back
                </div> */}
                <div
                  onClick={handleCourse}
                  className="col-10 mx-2"
                  style={{
                    height: "5.4vh",
                    background: "var(--primary-color, #065BD6)",
                    fontSize: "1.3vh",
                    fontWeight: "600",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <TopSkills CAND_ID={propsData} />
      )}
    </div>
  );
}

export default CourseExp;
