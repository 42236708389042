import React from "react";
import content_bg from "../Assets/sid_info_bg.png";
import reg_side_info from "../Assets/reg_side_info.png";
import "../App.css";
import reg_bg from "../Assets/reg_bg.png";
import nyinst_logo from "../Assets/nyinst_logo.png";
import green_round from "../Assets/green_round.png";
import star_img from "../Assets/star_points.png";
function Content() {
  return (
    <div
      style={{
        backgroundImage: `url(${reg_bg})`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <div className="container">
        <div align="center">
          <img
            src={nyinst_logo}
            alt="nyinst_logo"
            style={{ width: "8.4vh", height: "8.2vh", marginTop: "5.5vh" }}
          />
        </div>
        <div align="center" className="nyinst_mobile_text">
          NYINST Registration
        </div>
        <div style={{ marginLeft: "15px", marginTop: "2vh" }}>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "24px",
              color: "#FFFFFF",
            }}
          >
            Programs
          </div>
          <div
            style={{
              height: "4px",
              width: "80px",
              background:
                "linear-gradient(63deg, #7B9970 0%, rgba(56, 73, 50, 0.00) 100%)",
              marginTop: "0.4vh",
            }}
          ></div>
          <div className="d-flex" style={{ marginTop: "16px" }}>
            <div className="d-flex align-items-center">
              <img
                src={green_round}
                alt="green_round"
                style={{ width: "12px", height: "12px" }}
              />
              <span
                className="ps-1"
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                SUPER-100
              </span>
            </div>
            <div className="ps-3 d-flex align-items-center">
              <img
                src={green_round}
                alt="green_round"
                style={{ width: "12px", height: "12px" }}
              />
              <span
                className="ps-1"
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                TECHIE NERDS
              </span>
            </div>
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              color: "#FFFFFF",
              marginTop: "28px",
            }}
          >
            Our Offerings
          </div>
          <div
            style={{
              height: "4px",
              width: "80px",
              background:
                "linear-gradient(63deg, #F1B644 0%, rgba(241, 182, 68, 0.00) 100%)",
              marginTop: "0.4vh",
            }}
          ></div>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "16px" }}
          >
            <img
              src={star_img}
              alt="star_img"
              style={{ height: "12px", width: "12px" }}
            />
            <span
              className="ps-2"
              style={{
                fontSize: "13px",
                lineHeight: "20px",
                color: "#FFFFFF",
                fontWeight: "500",
              }}
            >
              100% Job Guarantee
            </span>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "16px" }}
          >
            <img
              src={star_img}
              alt="star_img"
              style={{ height: "12px", width: "12px" }}
            />
            <span
              className="ps-2"
              style={{
                fontSize: "13px",
                lineHeight: "20px",
                color: "#FFFFFF",
                fontWeight: "500",
              }}
            >
              100% Live Project
            </span>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "16px" }}
          >
            <img
              src={star_img}
              alt="star_img"
              style={{ height: "12px", width: "12px" }}
            />
            <span
              className="ps-2"
              style={{
                fontSize: "13px",
                lineHeight: "20px",
                color: "#FFFFFF",
                fontWeight: "500",
              }}
            >
              100% Residential / In Chennai
            </span>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "16px" }}
          >
            <img
              src={star_img}
              alt="star_img"
              style={{ height: "12px", width: "12px" }}
            />
            <span
              className="ps-2"
              style={{
                fontSize: "13px",
                lineHeight: "20px",
                color: "#FFFFFF",
                fontWeight: "500",
              }}
            >
              No Pay Until Job Is Confirmed
            </span>
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "24px",
              color: "#FFFFFF",
              marginTop: "16px",
            }}
          >
            Selection Process
          </div>
          <div
            style={{
              height: "4px",
              width: "80px",
              background:
                "linear-gradient(63deg, #5244F1 0%, #0D1117 100%, rgba(82, 68, 241, 0.00) 100%)",
              marginTop: "0.4vh",
            }}
          ></div>
          <div className="d-flex" style={{ marginTop: "16px" }}>
            <div className="d-flex align-items-center">
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#4B3FDD",
                }}
              >
                1.
              </div>
              <span
                className="ps-1"
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                HR Round
              </span>
            </div>
            <div className="ps-3 d-flex align-items-center">
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#4B3FDD",
                }}
              >
                2.
              </div>
              <span
                className="ps-1"
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                Group Discussion
              </span>
            </div>
          </div>
          <div
            className=" d-flex align-items-center"
            style={{ marginTop: "8px" }}
          >
            <div
              style={{
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#4B3FDD",
              }}
            >
              3.
            </div>
            <span
              className="ps-1"
              style={{
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#FFFFFF",
              }}
            >
              Aptitude & Tech Round
            </span>
          </div>
          <div className="d-flex" style={{ marginTop: "8px" }}>
            <div className="d-flex align-items-center">
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#4B3FDD",
                }}
              >
                4.
              </div>
              <span
                className="ps-1"
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                Prototype Project Assessment
              </span>
            </div>
            <div className="ps-3 d-flex align-items-center">
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#4B3FDD",
                }}
              >
                5.
              </div>
              <span
                className="ps-1"
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                Director Round
              </span>
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center "
            style={{
              backgroundImage:
                "linear-gradient(90deg, #3D35B0 0%, rgba(61, 53, 176, 0.00) 100%)",
              height: "6vh",
              borderRadius: "8px",
              marginTop:"20px"
            }}
          >
            
            <div
              style={{
                fontSize: "10px",
                color: "#FFFFFF",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
           <b>Note:</b>   Qualified Candidate Will Be Notified By The Program <br /> Manager
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Content;
