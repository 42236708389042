import React from "react";
import MobileNumberVerify from "./MobileNumberVerify";
import PersonalDetails from "./PersonalDetails";
import Content from "./Content";



function Form(){
    return(
        <>
        <MobileNumberVerify />
        {/* <PersonalDetails /> */}
        {/* <Content /> */}
        </>
    )
}

export default Form;