import MobileNumberVerify from "./Components/MobileNumberVerify";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PersonalDetails from "./Components/PersonalDetails";
import EducationDetails from "./Components/EducationDetails";
import CourseExp from "./Components/CourseExperience";
import TopSkills from "./Components/TopSkils";
import RegSuccessful from "./Components/RegistrationSuccessful";
import Form from "./Components/Form";
import { render } from "react-dom";
import "react-activity/dist/library.css";

import 'react-toastify/dist/ReactToastify.css';
function App() {
  return <>
{/*   
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<MobileNumberVerify />}/>
    <Route path="/personal-details" element={<PersonalDetails  />}/>
  </Routes>
  
  </BrowserRouter> */}
  {/* <MobileNumberVerify /> */}
  {/* <PersonalDetails /> */}
  {/* <EducationDetails /> */}

  {/* <CourseExp /> */}
  {/* <TopSkills /> */}
  {/* <RegSuccessful /> */}

  <Form />
  
  </>;
}

export default App;
