import React, { useState, useEffect } from "react";
import CourseExp from "./CourseExperience";
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import { render } from "react-dom";
import "react-activity/dist/library.css";
import { Bubbles } from "react-activity";
function EducationDetails(props) {
  const YOP = [
    { value: "0", text: "Select Year Of Passing" },
    { value: "401", text: "2022-2023" },
    { value: "402", text: "2022" },
    { value: "403", text: "2021" },
    { value: "404", text: "2020" },
    { value: "405", text: "2020" },
    { value: "406", text: "2019" },
    { value: "407", text: "2018" },
    { value: "408", text: "2017" },
    { value: "409", text: "2016" },
    { value: "410", text: "2015" },
    { value: "411", text: "2014" },
    { value: "412", text: "2013" },
    { value: "413", text: "2012" },
    { value: "414", text: "2011" },
    { value: "415", text: "2010" },
    { value: "416", text: "2009" },
    { value: "417", text: "2008" },
    { value: "418", text: "2007" },
    { value: "419", text: "2006" },
    { value: "420", text: "2005" },
    { value: "421", text: "2004" },
    { value: "422", text: "2003" },
    { value: "423", text: "2002" },
    { value: "424", text: "2001" },
    // { value: "425", text: "2014" },
    // { value: "426", text: "2014" },
    // { value: "427", text: "2014" },
    // { value: "428", text: "2014" },
    // { value: "429", text: "2014" },
    // { value: "430", text: "2014" },
    // { value: "431", text: "2014" },
    // { value: "432", text: "2014" },
    // { value: "433", text: "2014" },
    // { value: "434", text: "2014" },
    // { value: "435", text: "2014" },
    // { value: "436", text: "2014" },
    // { value: "437", text: "2014" },
    // { value: "438", text: "2014" },
    // { value: "439", text: "2014" },
    // { value: "440", text: "2014" },
    // { value: "441", text: "2014" },
    // { value: "442", text: "2014" },
    // { value: "443", text: "2014" },
    // { value: "444", text: "2014" },
    // { value: "445", text: "2014" },
    // { value: "446", text: "2014" },
    // { value: "447", text: "2014" },
  ];

  const [state, setState] = useState([]);
  const [collegeCityList, setCollegeCityList] = useState([]);
  const [seniorCityList, setSeniorCityList] = useState([]);
  const [higherCityList, setHigherCityList] = useState([]);
  const [collegeName, setCollegeName] = useState("");
  const [collegeState, setCollegeState] = useState();
  const [collegeCity, setCollegeCity] = useState("");
  const [collegeDeg, setCollegeDegree] = useState("");
  const [collegePassout, setCollegeYop] = useState("");
  const [collgBranch, setCollegeBranch] = useState("");
  const [collgMark, setCollegeMark] = useState("");
  const [collgMarkType, setCollegeMarkType] = useState("201");

  const [highSchoolName, setHighSchoolName] = useState("");
  const [highSchoolState, setHighSchoolState] = useState("");
  const [highSchoolCity, setHighSchoolCity] = useState("");
  const [highSchoolMark, setHighSchoolMark] = useState("");
  const [highSchoolMarkType, setHighSchoolMarkType] = useState("201");
  const [highSchoolPass, setHighSchoolPass] = useState("");

  const [seniorSchoolName, setseniorSchoolName] = useState("");
  const [seniorSchoolState, setseniorSchoolState] = useState("");
  const [seniorSchoolDist, setseniorSchoolDist] = useState("");
  const [seniorSchoolMark, setseniorSchoolMark] = useState("");
  const [seniorSchoolMarkType, setSeniorSchoolMarkType] = useState("201");
  const [seniorSchoolPass, setSeniorSchoolPass] = useState("");
  const [result, setResult] = useState();

  const [show, setShow] = useState(true);
  const handleShow = () => {
    setShow(!show);
  };
  // useEffect(() => {
  //   window.localStorage.setItem("cand_details", show);
  // });
  // useEffect(()=>{
  // const session=  window.localStorage.getItem("cand_details");
  // })
  const [perviousPage, setPreviousPage] = useState(true);
  // const handlePreviousPage = () => {
  //   setPreviousPage(!perviousPage);
  // };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify({ mod: "Candidate", actionType: "get-state-list" }),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "data");
        let responseData = res.data.XSCData;
        setState(responseData);
      });
      // Send Axios request here
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    let states = state.sort(function (a, b) {
      return a.STATE === b.STATE ? 0 : a.STATE < b.STATE ? -1 : 1;
    });
    setState(states);
    // console.log(states, "jk");
  }, []);
  const handleCollegeStateId = (e) => {
    // console.log(e.target.value);
    setCollegeState(e.target.value);

    // console.log(collegeState);
    getCollegeCityList(e.target.value);

    // getCityList()
  };

  const handleHighStateId = (e) => {
    // console.log(e.target.value);
    setHighSchoolState(e.target.value);
    // console.log(collegeState);
    getHigherCityList(e.target.value);
  };
  const handleSeniorStateId = (e) => {
    // console.log(e.target.value);
    setseniorSchoolState(e.target.value);
    // console.log(collegeState);
    getSeniorCityList(e.target.value);
  };

  const getCollegeCityList = (e) => {
    // console.log(e, "egg");

    const delayDebounceFn = setTimeout(() => {
      const getCity = {
        mod: "Candidate",
        actionType: "get-city-list",
        subAction: JSON.stringify({ STATE_ID: e }),
      };

      // console.log(getCity.subAction, "citysubaction");
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify(getCity),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "datass");
        let responseData = res.data.XSCData;
        setCollegeCityList(responseData);

        // let collg_city = collegeCityList.sort(function (a, b) {
        //   return a.CITY === b.CITY ? 0 : a.CITY < b.CITY ? -1 : 1;
        // });
        // console.log(collg_city,"jks")
        // setCollegeCityList(collg_city)
      });
    });
    return () => clearTimeout(delayDebounceFn);
  };
  const getHigherCityList = (e) => {
    // console.log(e, "egg");

    const delayDebounceFn = setTimeout(() => {
      const getCity = {
        mod: "Candidate",
        actionType: "get-city-list",
        subAction: JSON.stringify({ STATE_ID: e }),
      };

      // console.log(getCity.subAction, "citysubaction");
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify(getCity),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "datass");
        let responseData = res.data.XSCData;

        setHigherCityList(responseData);
      });
    });
    return () => clearTimeout(delayDebounceFn);
  };
  const getSeniorCityList = (e) => {
    // console.log(e, "egg");

    const delayDebounceFn = setTimeout(() => {
      const getCity = {
        mod: "Candidate",
        actionType: "get-city-list",
        subAction: JSON.stringify({ STATE_ID: e }),
      };

      // console.log(getCity.subAction, "citysubaction");
      axios({
        method: "post",
        url: "https://api.nyinst.com",
        data: qs.stringify(getCity),
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "datass");
        let responseData = res.data.XSCData;
        let cityList = responseData;
        setSeniorCityList(cityList);
      });
    });
    return () => clearTimeout(delayDebounceFn);
  };
  //  const twoCalls = (e) => {
  //    handleStateId()
  //     getCityList()
  //   }

  const propsData = props.CAND_ID;
  // console.log(propsData);

  const eduReqData = {
    mod: "Candidate",
    actionType: "add-edu-details",
    subAction: JSON.stringify({
      CAND_ID: propsData.candID,
      UNDER_GRADUATION: {
        COLLEGE_NAME: collegeName,
        STATE_ID: collegeState,
        CITY_ID: collegeCity,
        DEGREE: collegeDeg,
        YOP_ID: collegePassout,
        BRANCH: collgBranch,
        MARK_TYPE_ID: collgMarkType,
        MARK_SCORED: collgMark,
      },
      SENIOR_SECONDARY: {
        SENI_SCHOOL_NAME: seniorSchoolName,
        SENI_STATE_ID: seniorSchoolMark,
        SENI_CITY_ID: seniorSchoolDist,
        SENI_YOP_ID: seniorSchoolPass,
        SENI_MARK_TYPE_ID: seniorSchoolMarkType,
        SENI_MARK_SCORED: seniorSchoolMark,
      },
      HIGHER_SECONDARY: {
        HIGH_SCHOOL_NAME: highSchoolName,
        HIGH_STATE_ID: highSchoolState,
        HIGH_CITY_ID: highSchoolCity,
        HIGH_YOP_ID: highSchoolPass,
        HIGH_MARK_TYPE_ID: highSchoolMarkType,
        HIGH_MARK_SCORED: highSchoolMark,
      },
    }),
  };
  const handleEducation = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(eduReqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let validationData = eduReqData.subAction.UNDER_GRADUATION;

      // if (validationData.COLLEGE_NAME === "") {
      //   toast.error("fill");
      // }
      // console.log(res, "submitdata");
      // console.log(eduReqData.subAction, "edusubaction");
      res.data.XSCStatus === 0
        ? toast.success("Education Details Added Successfully!") && handleShow()
        : toast.error(res.data.XSCMessage);
      // handleShow();
    });
  };

  const [items, setItems] = useState(1);

  useEffect(() => {
    localStorage.setItem("items", JSON.stringify(items));
  }, [items]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items === 1) {
      setItems(items);
    }
  }, []);
  return (
    <>
      <div className="form_content" style={{ marginLeft: "80px" }}>
        {" "}
        {show ? (
          <div style={{ marginTop: "10vh" }}>
            {/* <div className="d-flex align-items-center justify-content-left">
              <div
                style={{
                  width: "2.0vh",
                  height: "2.0vh",
                  background: "#065BD6",
                  border: "1px solid #DFE1E4",
                  borderRadius: "50%",
                }}
              ></div>
              <div
                className="ms-1"
                style={{
                  height: "0.1vh",
                  width: "5.8vh",
                  background: "#DFE1E4",
                }}
              ></div>
              <div
                className="ms-1"
                style={{
                  width: "2.0vh",
                  height: "2.0vh",
                  //   background: "#065BD6",
                  border: "1px solid #DFE1E4",
                  borderRadius: "50%",
                }}
              ></div>
            </div> */}

            <div
              style={{
                marginTop: "5vh",
                fontSize: "2.9vh",
                fontWeight: "800",
                lineHeight: "3.7vh",
              }}
            >
              Education Details
            </div>
            <div
              style={{
                fontSize: "1.6vh",
                fontWeight: "500",
                lineHeight: "2.0vh",
                color: "#4F5969",
              }}
            >
              {" "}
              College, Higher Secondary, Senior Secondary
            </div>
            <div
              className="border-gradient border-gradient-purple position-relative"
              style={{
                // border: "2px solid blue",
                height: "66.7vh",
                // width: "29.25rem",
                paddingTop: "4.1vh",
                paddingLeft: "3.3vh",
                marginTop: "3.8vh",
              }}
            >
              <form
                onSubmit={handleEducation}
                style={{
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                  height: "50vh",
                }}
              >
                <div
                  className=""
                  style={{
                    fontSize: "1.4vh",
                    fontWeight: "600",
                    lineHeight: "1.6vh",
                    color: "#5B6574",
                  }}
                  align="center"
                >
                  Education - Under Graduation
                </div>
                <div>
                  <div className="position-relative">
                    <input
                      name="college_name"
                      id="college_name"
                      className="px-2"
                      // onChange={(e) => setCollege({ collegeName: e.target.value })}
                      onChange={(e) =>
                        setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                      }
                      type="text"
                      value={collegeName}
                      // placeholder="College/University Name"
                      style={{
                        height: "5.4vh",
                        marginTop: "2.5vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                      }}
                      required
                    />
                    <label
                      htmlFor="college_name"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      College/University Name
                    </label>
                  </div>

                  <div className="row mx-0" style={{ width: "90%" }}>
                    <div className="col-6 m-0 p-0">
                      {" "}
                      <select
                        placeholder="State"
                        name="State"
                        id="State"
                        style={{
                          width: "90%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          background: "white",

                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                        onChange={handleCollegeStateId}

                        // value={collegeState}
                      >
                        <option
                          // key={stateList.ID}
                          className="px-2"
                          placeholder="State"
                          value={0}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                          }}
                        >
                          Select State
                        </option>
                        {state.map((stateList) => {
                          // console.log(stateList.ID, "state");
                          return (
                            <option
                              key={stateList.ID}
                              className="px-2"
                              placeholder="State"
                              value={stateList.ID}
                              style={{
                                width: "100%",
                                marginTop: "2.5vh",
                                height: "5.4vh",
                                border: "1px solid var(--grey-g-40, #DFE1E4)",
                              }}
                            >
                              {stateList.STATE}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-6 m-0 p-0">
                      <select
                        placeholder="State"
                        name="State"
                        id="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          background: "white",

                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                        onChange={(e) => setCollegeCity(e.target.value)}
                      >
                        <option
                          // key={stateList.ID}
                          className="px-2"
                          placeholder="State"
                          // value={stateList.ID}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                            fontSize: "1.7vh",
                            fontWeight: "400",
                            lineHeight: "2.3vh",
                          }}
                        >
                          Select City
                        </option>
                        {collegeCityList &&
                          collegeCityList.map((cityList) => {
                            return (
                              <option
                                key={cityList.ID}
                                className="px-2"
                                placeholder="State"
                                value={cityList.ID}
                                onChange={getCollegeCityList}
                                style={{
                                  width: "100%",
                                  marginTop: "2.5vh",
                                  height: "5.4vh",
                                  border: "1px solid var(--grey-g-40, #DFE1E4)",
                                }}
                              >
                                {cityList.CITY}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="position-relative">
                    <input
                      name="degree"
                      id="degree"
                      className="px-2"
                      // onChange={(e) => setCollege({ collgDegree: e.target.value })}
                      onChange={(e) =>
                        setCollegeDegree(e.target.value.replace(/[^a-z]/gi,  ""))
                      }
                      type="text"
                      value={collegeDeg}
                      // placeholder="Degree"
                      style={{
                        height: "5.4vh",
                        marginTop: "2.5vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                      }}
                      required
                    />
                    <label
                      htmlFor="degree"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      Degree
                    </label>
                  </div>

                  <select className=""
                    required
                    placeholder="Gender"
                    value={collegePassout}
                    id="Gender"
                    style={{
                      width: "90%",
                      marginTop: "2.5vh",
                      height: "5.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      background: "white",
                      borderRadius: "4px",
                      fontSize: "1.7vh",
                      fontWeight: "400",
                    }}
                    // onChange={(e) => setCollege({ collgyop: e.target.value })}
                    onChange={(e) => setCollegeYop(e.target.value)}
                  >
                    {YOP.map((year) => {
                      return (
                        <option
                          className="px-2"
                          placeholder="Gender"
                          value={year.value}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                            fontSize: "1.5vh",
                            fontWeight: "400",
                            lineHeight: "2.3vh",
                          }}
                          // onSelect={(e) => setGender(e.target.value)}
                        >
                          {year.text}
                        </option>
                      );
                    })}
                  </select>
                  <div className="position-relative">
                    <input
                      id="branch"
                      name="branch"
                      className="px-2"
                      // onChange={(e) => setCollege({ collgBranch: e.target.value })}
                      onChange={(e) =>
                        setCollegeBranch(
                          e.target.value.replace(/[^ \w\s]/gi, "")
                        )
                      }
                      type="text"
                      value={collgBranch}
                      // placeholder="Branch"
                      style={{
                        height: "5.4vh",
                        marginTop: "2.5vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                      }}
                      required
                    />
                    <label
                      htmlFor="branch"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      Branch
                    </label>
                  </div>

                  <div className="position-relative">
                    <input
                      id="college_mark"
                      name="college_mark"
                      className="px-2"
                      // onChange={(e) => setCollege({ collgMark: e.target.value })}
                      onChange={(e) =>
                        setCollegeMark(e.target.value.replace(/\D/g, ""))
                      }
                      maxLength={3}
                      minLength={2}
                      type=""
                      value={collgMark}
                      // placeholder="Mark Scored"
                      style={{
                        height: "5.4vh",
                        marginTop: "2.5vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.3vh",
                      }}
                      required
                    />
                    <label
                      htmlFor="college_mark"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      Mark Scored
                    </label>
                    <select 
                      value={collgMarkType}
                      style={{
                        position: "absolute",
                        background: "#FAFAFB",
                        padding: "0.8vh 1.25vh",
                        color: "#5B6574",
                        fontSize: "1.3vh",
                        fontWeight: "600",
                        lineHeight: "2.00vh",
                        border: "none",
                        borderRadius: "0.8vh",
                        marginRight: "7vh",
                        marginTop: "1.3vh",
                      }}
                      className="position-absolute top-50 end-0 translate-middle-y "
                      onChange={(e) => setCollegeMarkType(e.target.value)}
                    >
                      <option value={201}>Percentage</option>
                      <option value={202}>CGPA</option>
                    </select>
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "1.4vh",
                    fontWeight: "600",
                    lineHeight: "1.6vh",
                    color: "#5B6574",
                    marginTop: "2vh",
                  }}
                  align="center"
                >
                  Education - Senior Secondary
                </div>
                <div>
                  <div className="position-relative">
                    <input
                      name="senior_name"
                      id="senior_name"
                      className="px-2"
                      onChange={(e) => setseniorSchoolName(e.target.value.replace(/[^ \w\s]./gi, ""))}
                      type="text"
                      value={seniorSchoolName}
                      // placeholder="School Name"
                      style={{
                        height: "5.4vh",
                        marginTop: "2.5vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                      }}
                      required
                    />
                    <label
                      htmlFor="senior_name"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      School Name
                    </label>
                  </div>

                  <div className="row mx-0" style={{ width: "90%" }}>
                    <div className="col-6 m-0 p-0">
                      <select
                        value={seniorSchoolState}
                        placeholder="State"
                        name="State"
                        id="State"
                        style={{
                          width: "90%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          background: "white",

                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                        onChange={handleSeniorStateId}
                      >
                        <option
                          // key={stateList.ID}
                          className="px-2"
                          placeholder="State"
                          // value={stateList.ID}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                          }}
                        >
                          Select States
                        </option>
                        {state.sort().map((stateList) => {
                          return (
                            <option
                              key={stateList.ID}
                              className="px-2"
                              placeholder="State"
                              value={stateList.ID}
                              style={{
                                width: "100%",
                                marginTop: "2.5vh",
                                height: "5.4vh",
                                border: "1px solid var(--grey-g-40, #DFE1E4)",
                              }}
                            >
                              {stateList.STATE}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-6 m-0 p-0">
                      <select
                        placeholder="State"
                        name="State"
                        id="State"
                        style={{
                          width: "90%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          background: "white",
                          marginLeft: "2vh",
                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                        onChange={(e) => setseniorSchoolDist(e.target.value)}
                      >
                        <option
                          // key={stateList.ID}
                          className="px-2"
                          placeholder="State"
                          // value={stateList.ID}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                            fontSize: "1.7vh",
                            fontWeight: "400",
                            lineHeight: "2.3vh",
                          }}
                        >
                          Select City
                        </option>
                        {seniorCityList &&
                          seniorCityList.sort().map((cityList) => {
                            return (
                              <option
                                key={cityList.ID}
                                className="px-2"
                                placeholder="State"
                                value={cityList.ID}
                                onChange={getSeniorCityList}
                                style={{
                                  width: "100%",
                                  marginTop: "2.5vh",
                                  height: "5.4vh",
                                  border: "1px solid var(--grey-g-40, #DFE1E4)",
                                  fontSize: "1.7vh",
                                  fontWeight: "400",
                                  lineHeight: "2.3vh",
                                }}
                              >
                                {cityList.CITY}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <select
                    required
                    placeholder="Gender"
                    value={seniorSchoolPass}
                    id="Gender"
                    style={{
                      width: "90%",
                      marginTop: "2.5vh",
                      height: "5.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      background: "white",
                      borderRadius: "4px",
                    }}
                    onChange={(e) => setSeniorSchoolPass(e.target.value)}
                  >
                    {YOP.map((year) => {
                      return (
                        <option
                          className="px-2"
                          placeholder="Gender"
                          value={year.value}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                            fontSize: "1.7vh",
                            fontWeight: "400",
                            lineHeight: "2.3vh",
                          }}
                          // onSelect={(e) => setGender(e.target.value)}
                        >
                          {year.text}
                        </option>
                      );
                    })}
                  </select>
                  <div className="position-relative">
                    <input
                      id="senior_mark"
                      name="senior_name"
                      className="px-2"
                      onChange={(e) =>
                        setseniorSchoolMark(e.target.value.replace(/\D/g, ""))
                      }
                      type=""
                      // pattern="[\d]{3}"
                      value={seniorSchoolMark}
                      // placeholder="Mark Scored"
                      style={{
                        height: "5.4vh",
                        marginTop: "2.5vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                      }}
                      required
                      minLength={2}
                      maxLength={3}
                    />
                    <label
                      htmlFor="senior_mark"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      Mark Scored
                    </label>
                    <select
                      style={{
                        position: "absolute",
                        background: "#FAFAFB",
                        padding: "0.8vh 1.25vh",
                        color: "#5B6574",
                        fontSize: "1.3vh",
                        fontWeight: "600",
                        lineHeight: "2.00vh",
                        border: "none",
                        borderRadius: "0.8vh",
                        marginRight: "7vh",
                        marginTop: "1.3vh",
                      }}
                      className="position-absolute top-50 end-0 translate-middle-y"
                      value={seniorSchoolMarkType}
                      onChange={(e) => setSeniorSchoolMarkType(e.target.value)}
                    >
                      <option value={201}>Percentage</option>
                      <option value={202}>CGPA</option>
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "1.4vh",
                    fontWeight: "600",
                    lineHeight: "1.6vh",
                    color: "#5B6574",
                    marginTop: "2vh",
                  }}
                  align="center"
                >
                  Education - Higher Secondary
                </div>
                <div>
                  <div className="position-relative">
                    {" "}
                    <input
                      id="school_name"
                      name="school_name"
                      className="px-2"
                      onChange={(e) =>
                        setHighSchoolName(
                          e.target.value.replace(/[^a-z]/gi, "")
                        )
                      }
                      type="text"
                      value={highSchoolName}
                      // placeholder="School Name"
                      style={{
                        height: "5.4vh",
                        marginTop: "2.5vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                      }}
                      required
                    />
                    <label
                      htmlFor="school_name"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      School Name
                    </label>
                  </div>

                  <div className="row mx-0" style={{ width: "90%" }}>
                    <div className="col-6 m-0 p-0">
                      <select
                        placeholder="State"
                        name="State"
                        id="State"
                        style={{
                          width: "100%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          background: "white",

                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                        onChange={handleHighStateId}
                        // onClick={<Bubbles />}
                      >
                        <option
                          // key={stateList.ID}
                          className="px-2"
                          placeholder="State"
                          // value={stateList.ID}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                            fontSize: "1.7vh",
                            fontWeight: "400",
                            lineHeight: "2.3vh",
                          }}
                        >
                          Select State
                        </option>
                        {state.map((stateList) => {
                          return (
                            <option
                              key={stateList.ID}
                              className="px-2"
                              placeholder="State"
                              value={stateList.ID}
                              style={{
                                width: "100%",
                                marginTop: "2.5vh",
                                height: "5.4vh",
                                border: "1px solid var(--grey-g-40, #DFE1E4)",
                                fontSize: "1.7vh",
                                fontWeight: "400",
                                lineHeight: "2.3vh",
                              }}
                            >
                              {stateList.STATE}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-6 m-0 p-0">
                      <select
                        placeholder="State"
                        name="State"
                        id="State"
                        style={{
                          width: "90%",
                          marginTop: "2.5vh",
                          height: "5.4vh",
                          border: "1px solid var(--grey-g-40, #DFE1E4)",
                          background: "white",
                          marginLeft: "2vh",
                          fontSize: "1.7vh",
                          fontWeight: "400",
                          lineHeight: "2.4vh",
                        }}
                        onChange={(e) => setHighSchoolCity(e.target.value)}
                      >
                        <option
                          // key={stateList.ID}
                          className="px-2"
                          placeholder="State"
                          // value={stateList.ID}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                            fontSize: "1.7vh",
                            fontWeight: "400",
                            lineHeight: "2.3vh",
                          }}
                        >
                          Select City
                        </option>
                        {higherCityList &&
                          higherCityList.map((cityList) => {
                            return (
                              <option
                                key={cityList.ID}
                                className="px-2"
                                placeholder="State"
                                value={cityList.ID}
                                onChange={getHigherCityList}
                                style={{
                                  width: "100%",
                                  marginTop: "2.5vh",
                                  height: "5.4vh",
                                  border: "1px solid var(--grey-g-40, #DFE1E4)",
                                }}
                              >
                                {cityList.CITY}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <select
                    required
                    placeholder="Gender"
                    value={highSchoolPass}
                    id="Gender"
                    style={{
                      width: "90%",
                      marginTop: "2.5vh",
                      height: "5.4vh",
                      border: "1px solid var(--grey-g-40, #DFE1E4)",
                      background: "white",
                      borderRadius: "4px",
                    }}
                    // onChange={(e) => setCollege({ collgyop: e.target.value })}
                    onChange={(e) => setHighSchoolPass(e.target.value)}
                  >
                    {YOP.map((year) => {
                      return (
                        <option
                          className="px-2"
                          placeholder="Gender"
                          value={year.value}
                          style={{
                            width: "100%",
                            marginTop: "2.5vh",
                            height: "5.4vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                            fontSize: "1.7vh",
                            fontWeight: "400",
                            color: "#07152C",
                          }}
                          // onSelect={(e) => setGender(e.target.value)}
                        >
                          {year.text}
                        </option>
                      );
                    })}
                  </select>
                  <div className="position-relative">
                    <input
                      name="high_mark"
                      id="high_mark"
                      className="px-2"
                      onChange={(e) =>
                        setHighSchoolMark(e.target.value.replace(/\D/g, ""))
                      }
                      type=""
                      // pattern="[0-9]{10}"
                      maxLength={3}
                      value={highSchoolMark}
                      // placeholder="Mark Scored"
                      style={{
                        height: "5.4vh",
                        marginTop: "2.5vh",
                        width: "90%",
                        fontSize: "1.7vh",
                        fontWeight: "400",
                        lineHeight: "2.4vh",
                        border: "1px solid var(--grey-g-40, #DFE1E4)",
                        color: "#07152C",
                      }}
                      required
                    />
                    <label
                      htmlFor="high_mark"
                      className="position-absolute start-0 top-50 px-2 input-label"
                    >
                      Mark Scored
                    </label>
                    <select
                      style={{
                        position: "absolute",
                        background: "#FAFAFB",
                        padding: "0.8vh 1.25vh",
                        color: "#5B6574",
                        fontSize: "1.3vh",
                        fontWeight: "600",
                        lineHeight: "2.00vh",
                        border: "none",
                        borderRadius: "0.8vh",
                        marginRight: "7vh",
                        marginTop: "1.3vh",
                      }}
                      className="position-absolute top-50 end-0 translate-middle-y"
                      onChange={(e) => setHighSchoolMarkType(e.target.value)}
                      value={highSchoolMarkType}
                    >
                      <option value={201}>Percentage</option>
                      <option value={202}>CGPA</option>
                    </select>
                  </div>
                </div>
                <div className="position-absolute bottom-0 w-100 mb-3">
                  <div className="row">
                    {/* <div
                    onClick={handleShow}
                    className="col-4 mx-2"
                    style={{
                      height: "5.4vh",
                      background: "var(--primary-color, #EBECEE)",
                      fontSize: "1.3vh",
                      fontWeight: "600",
                      color: "#5B6574",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                    }}
                  >
                    Back
                  </div> */}
                    <button
                      type="submit"
                      // onClick={handleEducation}
                      className="col-10 mx-2  btn"
                      style={{
                        height: "5.4vh",
                        background: "var(--primary-color, #065BD6)",
                        fontSize: "1.3vh",
                        fontWeight: "600",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <CourseExp CAND_ID={propsData} />
        )}
      </div>
    </>
  );
}
export default EducationDetails;
