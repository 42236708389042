import React, { useEffect, useState } from "react";
import verification_tick from "../Assets/verification-tick.png";
import india_img from "../Assets/india.png";
import back_button from "../Assets/back_arrow_button.svg";
import "../App.css";
import { PinInput } from "react-input-pin-code";
import tick_vector from "../Assets/tick_vector.png";
import EmailVerify from "./EmailVerify";
import content_bg from "../Assets/side_info.png";
import axios from "axios";
import qs from "qs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mobile_side_img from "../Assets/mob_reg_img.png";
import PersonalDetails from "./PersonalDetails";
import { json } from "react-router-dom";
import mail_icon from "../Assets/mail_icon.png";
import Content from "./Content";
import { Dots } from "react-activity";
import DesktopContent from "./DesktopContent";
import { MetroSpinner } from "react-spinners-kit";
import ReactSpinnerTimer from "react-spinner-timer";
import { SpinnerCircular } from "spinners-react";
function MobileNumberVerify() {
  const [mobile, setMobile] = useState("");

  // const validateMobile = "/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/"

  const [values, setValues] = useState(["", "", ""]);

  const [show, setShow] = useState(true);
  const [emailbg, setEmailBg] = useState("");
  const [showEmail, setShowEmail] = useState(true);
  const [emailValue, setEmailValue] = useState("");
  const [seconds, setSeconds] = useState(30);
  // const[resendOtp, setResendOtp] = useState("")

  const [userDetails, setUserDatails] = useState({
    candID: "",
    OTP: "",
    mobileNo: "",
    item: 1,
    resendOTP: "",
  });
  // useEffect(() => {
  //   localStorage.setItem("items", JSON.stringify(userDetails.item));
  // }, [userDetails.item]);

  // useEffect(() => {
  //   const item = JSON.parse(localStorage.getItem("items"));
  //   if (item) {
  //     setUserDatails({ item: item });
  //   }
  // }, []);

  const handleShow = () => {
    setShow(!show);
    setValues(["","",""])
  };
  const [handleTick, setHandleTick] = useState(
    <div
      style={{
        color: "white",
        fontSize: "1.6vh",
        lineHeight: "2.0vh",
        fontWeight: "600",
      }}
    >
      1
    </div>
  );
  const [personalDetShow, setPersonalDetShow] = useState(true);

 

  const handleSubmit = (e) => {
    // console.log("ghe");
    e.preventDefault();

    const otpreqData = {
      mod: "AA",
      actionType: "get-otp-via-sms",
      subAction: JSON.stringify({
        MOBILE_NUMBER: `+91${mobile}`,
      }),
    };

    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(otpreqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      // console.log(res, "data");
      // console.log(`+91${mobile}`);
      setUserDatails({
        candID: res.data.XSCData.CAND_ID,
        OTP: res.data.XSCData.SMS_OTP,
        mobileNo: res.data.XSCData.MOBILE_NUMBER,
      });

      if (res.data.XSCMessage === "OTP Send Successfully") {
        handleShow();
        // }
      } else {
        alert("Enter Valid Mobile No");
        // handleShow()
      }
    });
  };
  const resendOtpReqData = {
    mod: "AA",
    actionType: "resend-sms-otp",
    subAction: JSON.stringify({
      CAND_ID: userDetails.candID,
      MOBILE_NUMBER: userDetails.mobileNo,
    }),
  };
  const resendOTP = (e) => {
    setSeconds(30);
    e.preventDefault();

    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(resendOtpReqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      // console.log(res, "data");
      setUserDatails({
        // candID: res.data.XSCData.CAND_ID,
        resendOTP: res.data.XSCData.SMS_OTP,
        // mobileNo: res.data.XSCData.MOBILE_NUMBER,
      });

      // console.log(
      //   userDetails.mobileNo,
      //   userDetails.OTP,
      //   userDetails.candID,
      //   "od"
      // );
      // let userOtp = values.toString().replace(/[,]/g, "");
      // console.log(userOtp, "otp");
      // if (userOtp === userDetails.OTP) {
      //   toast.success("sms verified successfuly");
      //   handleTickImg();
      // } else {
      //   toast.error("Invalid OTP Enter");
      //   // handleTickImg();
      // }
    });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        setSeconds(59);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const submitReqData = {
    mod: "AA",
    actionType: "sms-otp-verification",
    subAction: JSON.stringify({
      CAND_ID: userDetails.candID,
      SMS_OTP: userDetails.OTP,
      MOBILE_NUMBER: userDetails.mobileNo,
    }),
  };
  const handleOTPSubmit = (e) => {
    // console.log("ghe");
    e.preventDefault();

    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(submitReqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      // console.log(res, "data");
      // console.log(submitReqData.subAction,"otsub")
      // console.log(
      //   userDetails.mobileNo,
      //   userDetails.OTP,
      //   userDetails.candID,
      //   "od"
      // );
      let userOtp = values.toString().replace(/[,]/g, "");
      // console.log(userOtp, "otp");
      if (userOtp === userDetails.OTP || userDetails.resendOTP) {
        toast.success("sms verified successfuly");
        handleTickImg();
      } else {
        toast.error("Invalid OTP Enter");
        // handleTickImg();
      }
    });
    // console.log(values.toString().replace(/[,]/g, ""), "values");
  };

  const handleTickImg = () => {
    setShowEmail(!showEmail);
    setEmailValue("2");
    setEmailBg("#065BD6");
    setHandleTick(
      <div
        style={{
          color: "white",
          fontSize: "1.6vh",
          lineHeight: "2.0vh",
          fontWeight: "600",
        }}
      >
        <img src={tick_vector} alt="tick_img" style={{ width: "2.5vh" }} />
      </div>
    );
  };

  // EMAIL VERIFICATION CODE
  const [Email, setEmail] = useState("");
  const [emailValues, setEmailValues] = useState(["", "", ""]);
  const [error, setError] = useState("");
  const [emailshow, setEmailShow] = useState(true);
  // const [personalDetShow, setPersonalDetShow] = useState(true);

  const [userData, setUserData] = useState({
    userEmail: "",
    emailOTP: "",
    resendEmailOtp: "",
  });

  const handleEmailShow = () => {
    setEmailShow(!emailshow);
    setEmail(Email);
  };
  const handlePersonalDetShow = () => {
    setPersonalDetShow(!personalDetShow);
  };
  // let propsData = props.CAND_ID;

  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const otpReqData = {
    mod: "AA",
    actionType: "get-otp-via-email",
    subAction: JSON.stringify({
      EMAIL: Email,
      CAND_ID: userDetails.candID,
    }),
  };


  const handleEmailSubmit = (e) => {
    // console.log("ghe");
    setLoading(true);
   
    e.preventDefault();
    // {
    //   result !== "OTP Send Successfully"  ? setLoading(true) :  setLoading(false);
    // }
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(otpReqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      // console.log(res, "emaildata");/

      // console.log(props.CAND_ID);
      if (res.data.XSCMessage === "OTP Send Successfully") {
        setResult(res.data.XSCMessage);
        handleEmailShow();
        setUserData({
          emailOTP: res.data.XSCData.EMAIL_OTP,
          userEmail: res.data.XSCData.EMAIL,
        });
        // }
        // console.log(userData.userEmail,"")
      } else {
        toast.error("Invalid Mail Id");
        // handleEmailShow();
      }
    });
  };
  const resendEmailReqData = {
    mod: "AA",
    actionType: "resend-email-otp",
    subAction: JSON.stringify({
      CAND_ID: userDetails.candID,
      EMAIL:userData.userEmail,
    }),
  };
  const resendEmailOTP = (e) => {
    setSeconds(30);
    e.preventDefault();

    axios({
      method: "post",
      url: "http://192.168.10.28/nyinst_api_php/",
      data: qs.stringify(resendEmailReqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      // console.log(res, "data");
      setUserData({
        // candID: res.data.XSCData.CAND_ID,
        resendEmailOtp: res.data.XSCData.EMAIL_OTP,
        // mobileNo: res.data.XSCData.MOBILE_NUMBER,
      });

      
      // let userOtp = values.toString().replace(/[,]/g, "");
      // console.log(userOtp, "otp");
      // if (userOtp === userDetails.OTP) {
      //   toast.success("sms verified successfuly");
      //   handleTickImg();
      // } else {
      //   toast.error("Invalid OTP Enter");
      //   // handleTickImg();
      // }
    });
  };
  const submitEmailReqData = {
    mod: "AA",
    actionType: "email-otp-verification",
    subAction: JSON.stringify({
      CAND_ID: userDetails.candID,
      EMAIL_OTP: userData.emailOTP,
      EMAIL: userData.userEmail,
    }),
  };
  const handleEmailOTPSubmit = (e, lap) => {
    // console.log(submitEmailReqData.subAction, "sub");
    e.preventDefault();

    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(submitEmailReqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      // console.log(res, "data");
      let userOtp = emailValues.toString().replace(/[,]/g, "");
      // console.log(userOtp, "userotp", userData.emailOTP);
      if (userOtp === userData.emailOTP || userData.resendEmailOtp) {
        toast.success("EMAIL verified Successfully");
        handlePersonalDetShow();
      } else {
        toast.error("Invalid OTP Enter");
        // handlePersonalDetShow();
      }

      // console.log`${props.CAND_ID.CAND_ID}`
      // console.log(
      //   userDetails.mobileNo,
      //   userDetails.OTP,
      //   userDetails.candID,
      //   "od"
      // );
      // let userOtp = values.toString().replace(/[,]/g, "");
      // console.log(userOtp, "otp");
      // if (userOtp === userDetails.OTP) {
      //   toast.success("sms verified successfuly");
      //   handleTickImg();
      // } else {
      //   toast.error("Invalid OTP Enter");
      // }
    });
    // console.log(values.toString().replace(/[,]/g, ""), "values");
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 40000);
  });

  return (
    <div className="containers">
      <ToastContainer />

      <div className="row p-0 m-0">
        <div className="col-lg-6  p-0 m-0">
          {window.innerWidth < 667 ? (
            <Content />
          ) : (
            // <img
            //   className="img"
            //   src={content_bg}
            //   width="100%"
            //   style={{ height: "100vh", width: "" }}
            // />
            <DesktopContent />
          )}
        </div>
        <div className="col-md-6 mb-5">
          {personalDetShow ? (
            <div
              style={{ marginTop: "10vh", marginLeft: "80px" }}
              className="form_content"
            >
              <div
                style={{
                  marginTop: "3vh",
                  fontSize: "2.9vh",
                  fontWeight: "800",
                  lineHeight: "3.7vh",
                }}
              >
                Phone & Email Verification
              </div>
              <div
                style={{
                  fontSize: "1.6vh",
                  fontWeight: "500",
                  lineHeight: "2.0vh",
                  color: "#4F5969",
                }}
              >
                {" "}
                Fill in the details you’ll get an OTP to Verify - *2
                verification
              </div>
              <div
                className="border-gradient border-gradient-purple"
                style={{
                  // border: "2px solid blue",
                  height: "66.7vh",
                  // width: "40vw",
                  paddingTop: "4.1vh",
                  paddingLeft: "3.3vh",
                  marginTop: "3.8vh",
                }}
              >
                <div className="d-flex align-items-center justify-content-left">
                  <div
                    style={{
                      width: "3.75vh",
                      height: "3.75vh",
                      background: "#065BD6",
                      border: "1px solid #DFE1E4",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "1.6vh",
                      lineHeight: "2.0vh",
                      fontWeight: "600",
                    }}
                  >
                    {handleTick}
                  </div>
                  <div
                    className="ms-1"
                    style={{
                      height: "0.1vh",
                      width: "5.8vh",
                      background: "#DFE1E4",
                    }}
                  ></div>
                  <div
                    className="ms-1"
                    style={{
                      width: "3.75vh",
                      height: "3.75vh",
                      background: emailbg,
                      border: "1px solid #DFE1E4",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "1.6vh",
                      lineHeight: "2.0vh",
                      fontWeight: "600",
                    }}
                  >
                    {emailValue}
                  </div>
                </div>
                {show ? (
                  <div>
                    <div
                      style={{
                        marginTop: "2.5vh",
                        fontSize: "2.5vh",
                        lineHeight: "3.33vh",
                        fontWeight: "800",
                      }}
                    >
                      Enter Mobile Number
                    </div>
                    <div className="position-relative">
                      <img
                        className="position-absolute"
                        src={india_img}
                        alt="india"
                        style={{
                          width: "2.6vh",
                          height: "2.0vh",
                          top: "4vh",
                          left: "1.6vh",
                        }}
                      />
                      <form onSubmit={handleSubmit}>
                        <input
                          onChange={(e) =>
                            setMobile(e.target.value.replace(/\D/g, ""))
                          }
                          type="tel"
                          value={mobile}
                          placeholder="0000-000-000"
                          style={{
                            height: "5.4vh",
                            marginTop: "2.5vh",
                            width: "90%",
                            fontSize: "1.7vh",
                            fontWeight: "700",
                            lineHeight: "2.0vh",
                            paddingLeft: "6vh",
                            border: "1px solid var(--grey-g-40, #DFE1E4)",
                            color: "#07152C",
                            borderRadius: "4px",
                          }}
                          maxLength={10}
                          pattern="[0-9]{10}"
                          required
                        />
                        <div className="" style={{}}>
                          <button
                            type="submit"
                            className=""
                            style={{
                              background: "#065BD6",
                              height: "5.4vh",
                              width: "12.9vh",
                              borderRadius: "4px",
                              marginTop: "2.0vh",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "1.3vh",
                              lineHeight: "2.0vh",
                              fontWeight: "600",
                              color: "#FFFFFF",
                              border: "none",
                              // background:"none"
                            }}
                          >
                            Send OTP
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div>
                    {showEmail ? (
                      <>
                        {" "}
                        <div style={{ marginTop: "2.5vh", cursor: "pointer" }}>
                          <img
                            style={{ cursor: "pointer" }}
                            src={back_button}
                            alt="back_button"
                            onClick={handleShow}
                          />
                          <div
                            style={{
                              marginTop: "2.0vh",
                              fontSize: "2.5vh",
                              lineHeight: "3.33vh",
                              fontWeight: "800",
                            }}
                          >
                            OTP Verification
                          </div>
                          <div
                            style={{
                              fontSize: "1.3vh",
                              fontWeight: "500",
                              color: "#5B6574",
                              lineHeight: "2.0vh",
                            }}
                          >
                            SMS Send to this number
                            <span
                              style={{
                                fontSize: "1.3vh",
                                fontWeight: "800",
                                color: "#222F43",
                                lineHeight: "2.0vh",
                              }}
                            >
                              {""}
                              {userDetails.mobileNo}
                            </span>{" "}
                          </div>
                          <PinInput
                            containerClassName=" d-flex justify-content-start "
                            type="number"
                            placeholder=""
                            inputStyle={{
                              height: "5.2vh",
                              width: "5.2vh",
                              borderRadius: "0.8vh",
                              border: "1px solid #DFE1E4",
                              marginTop: "3vh",
                            }}
                            values={values}
                            onChange={(value, index, values) =>
                              setValues(values)
                            }
                            // validBorderColor="green"
                            focusBorderColor="#065BD6"
                            // required
                          />
                        </div>
                        <div>
                          <button
                            onClick={handleOTPSubmit}
                            className="btn"
                            style={{
                              height: "",
                              width: "12.9vh",
                              background: "#065BD6",
                              fontSize: "1.3vh",
                              fontWeight: "600",
                              lineHeight: "2.0vh",
                              color: "white",
                              marginTop: "3.7vh",
                              padding: "1.7vh",
                            }}
                          >
                            Verify
                          </button>
                          <div className="countdown-text mt-3">
                            {/* {seconds > 0 ? (
                            <p>
                              Time Remaining:{" "}
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                          ) : (
                            <p>Didn't recieve code?</p>
                          )} */}

                            <div
                              // disabled={seconds > 0}
                              style={{
                                fontSize: "1.7vh",
                                fontWeight: "600",
                                lineHeight: "2vh",
                                color: "#6A7380",
                                cursor: "pointer",
                              }}
                              onClick={resendOTP}
                            >
                              <u>
                                {" "}
                                Resend OTP{" "}
                                {seconds < 10 ? `0${seconds}` : seconds} secs
                              </u>
                            </div>
                          </div>
                        </div>
                        {/* <u>
                          <div
                            style={{
                              fontSize: "1.3vh",
                              fontWeight: "600",
                              color: "#6A7380",
                              lineHeight: "2.0vh",
                              marginTop: "1.6vh",
                            }}
                          >
                            Resend OTP in 26secs
                          </div>
                        </u> */}
                      </>
                    ) : (
                      <div>
                        {emailshow ? (
                          <div>
                            <div
                              style={{
                                marginTop: "2.5vh",
                                fontSize: "2.5vh",
                                lineHeight: "3.33vh",
                                fontWeight: "800",
                              }}
                            >
                              Enter Email Address
                            </div>
                            <div className="position-relative">
                              <img
                                className="position-absolute"
                                src={mail_icon}
                                alt="india"
                                style={{
                                  width: "2.6vh",
                                  height: "2.0vh",
                                  top: "4vh",
                                  left: "1.6vh",
                                }}
                              />
                              <input
                                onChange={(e) => setEmail(e.target.value)}
                                // type="tel"
                                value={Email}
                                placeholder="abc@gmail.com"
                                type="email"
                                style={{
                                  height: "5.4vh",
                                  marginTop: "2.5vh",
                                  width: "90%",
                                  fontSize: "1.7vh",
                                  fontWeight: "500",
                                  lineHeight: "2.0vh",
                                  paddingLeft: "6vh",
                                  border: "1px solid var(--grey-g-40, #DFE1E4)",
                                  color: "#07152C",
                                  borderRadius: "4px",
                                }}
                                required
                              />
                            </div>

                            <div
                              onClick={handleEmailSubmit}
                              className=" d-flex"
                              // style={{
                              //   background: "#065BD6",
                              //   height: "5.4vh",
                              //   width: "14.9vh",
                              //   borderRadius: "4px",
                              //   marginTop: "2.0vh",
                              //   display: "flex",
                              //   alignItems: "center",
                              //   justifyContent: "center",
                              // }}
                            >
                              <button
                                className="btn  d-flex align-items-center justify-content-center"
                                style={{
                                  background: "#065BD6",
                                  height: "5.4vh",
                                  width: "12.9vh",
                                  borderRadius: "4px",
                                  marginTop: "2.0vh",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "1.3vh",
                                  lineHeight: "2.0vh",
                                  fontWeight: "600",
                                  color: "#FFFFFF",
                                  border: "none",
                                  // background:"none"
                                }}
                              >
                                Send OTP{" "}
                                <span className="ps-3">
                                  <SpinnerCircular
                                    enabled={loading}
                                    size={20}
                                  />
                                  {/* <ReactSpinnerTimer
                                    className="secs"
                                    // loading={loading}
                                    isFinish={true}
                                    timeInSeconds={30}
                                    totalLaps={30}
                                    isRefresh={refreshSpinner}
                                    onLapInteraction={handleChange}
                                  /> */}
                                </span>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <>
                              <div style={{ marginTop: "2.5vh" }}>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={back_button}
                                  alt="back_button"
                                  onClick={handleEmailShow}
                                />
                                <div
                                  style={{
                                    marginTop: "2.0vh",
                                    fontSize: "2.5vh",
                                    lineHeight: "3.33vh",
                                    fontWeight: "800",
                                  }}
                                >
                                  OTP Verification
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.3vh",
                                    fontWeight: "500",
                                    color: "#5B6574",
                                    lineHeight: "2.0vh",
                                  }}
                                >
                                  Mail sent to this id
                                  <span
                                    style={{
                                      fontSize: "1.3vh",
                                      fontWeight: "800",
                                      color: "#222F43",
                                      lineHeight: "2.0vh",
                                    }}
                                  >
                                    {""} {Email}
                                  </span>{" "}
                                </div>
                                <PinInput
                                  containerClassName=" d-flex justify-content-start "
                                  type="number"
                                  placeholder=""
                                  inputStyle={{
                                    height: "5.2vh",
                                    width: "5.2vh",
                                    borderRadius: "0.8vh",
                                    border: "1px solid #DFE1E4",
                                    marginTop: "3vh",
                                  }}
                                  values={emailValues}
                                  onChange={(value, index, values) =>
                                    setEmailValues(values)
                                  }
                                  // validBorderColor="green"
                                  focusBorderColor="#065BD6"
                                  // required
                                />
                              </div>
                              <div>
                                <button
                                  onClick={handleEmailOTPSubmit}
                                  className="btn"
                                  style={{
                                    height: "",
                                    width: "12.9vh",
                                    background: "#065BD6",
                                    fontSize: "1.3vh",
                                    fontWeight: "600",
                                    lineHeight: "2.0vh",
                                    color: "white",
                                    marginTop: "3.7vh",
                                    padding: "1.7vh",
                                  }}
                                >
                                  Verify
                                </button>
                              </div>
                              <div className="countdown-text mt-3">
                            {/* {seconds > 0 ? (
                            <p>
                              Time Remaining:{" "}
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                          ) : (
                            <p>Didn't recieve code?</p>
                          )} */}

                            <div
                              // disabled={seconds > 0}
                              style={{
                                fontSize: "1.7vh",
                                fontWeight: "600",
                                lineHeight: "2vh",
                                color: "#6A7380",
                                cursor: "pointer",
                              }}
                              onClick={resendEmailOTP}
                            >
                              <u>
                                {" "}
                                Resend OTP{" "}
                                {seconds < 10 ? `0${seconds}` : seconds} secs
                              </u>
                            </div>
                          </div>
                              {/* <u>
                                <div
                                  style={{
                                    fontSize: "1.3vh",
                                    fontWeight: "600",
                                    color: "#6A7380",
                                    lineHeight: "2.0vh",
                                    marginTop: "1.6vh",
                                  }}
                                >
                                  Resend OTP in 26secs
                                </div>
                              </u> */}
                            </>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <PersonalDetails CAND_ID={userDetails} />
          )}
        </div>
      </div>
    </div>
  );
}
export default MobileNumberVerify;
